<script setup>
import { ref, onMounted } from "vue";
const code = ref("");
import { setMeta } from "@/utils/util";
import { list } from "@/utils/blog";
const result = ref("");
import router from "@/router";

onMounted(() => {
  document.title = "精选技术文章";
  setMeta("description", "博客,技术文章,精选技术文章,技术答疑");
  setMeta("keywords", "博客,文章,技术,blog,技术答疑");
});

const lists = ref(list)

const goDetail = (item) => {
    router.push({
      path: "/zh/blogDetail",
      query: {
        title: item.title,
        href: item.href
      }
    });
}
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">精选技术文章</h1>
    <el-card class="box-card" v-for="item in lists">
            <div class="card-header">
                <h2><el-link class="title" target="_blank" @click="goDetail(item)">{{ item.title }}</el-link></h2>
                <p class="right-button" text>分享自：<span class="colorful">NickyXing</span></p>
            </div>
            <el-tag class="tag-type" type="primary">技术</el-tag>
            <el-tag class="tag-type" type="success">成长</el-tag>
            <span class="gray-text">发布于{{ item.time }}</span>
    </el-card>
    <br>
    <el-pagination background layout="prev, pager, next" :total="6" />
  </div>
</template>
<style lang="scss" scoped>
    .box-card {
        margin-top: 10px;
        cursor: pointer;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .tag-type {
        margin-right: 10px;
    }
    .gray-text {
        color: #888888;
    }
</style>
