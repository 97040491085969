<script setup>
import { onMounted, reactive, ref } from "vue";
import { setMeta } from '@/utils/util'

const data = reactive({
  number: true,
  lowerLetter: true,
  upperLetter: true,
  specialChar: false,
  length: 16,
  chars: {
    number: "0123456789",
    lowerLetter: "abcdefghijklmnopqrstuvwxyz",
    upperLetter: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    specialChar: "~!@#$%^&*()[{]}-_=+|;:'\",<.>/?`",
  },
  resultContent: "",
});
const convert = () => {
  let e = ["number", "lowerLetter", "upperLetter", "specialChar"]
      .filter((e) => data[e])
      .map((e) => data.chars[e])
      .join(""),
    t = [],
    r = [],
    n = 0;
  for (let l = 0; l < data.length; l++) {
    do {
      n = Math.floor(Math.random() * e.length);
    } while (r.includes(n) && r.length < e.length);
    r.push(n), t.push(e[n]);
  }
  data.resultContent = t.join("");
};

onMounted(() => {
  document.title = "Random Password Generator";
  setMeta("description", "Random Password Generator,Random string generator")
  setMeta("keywords", "Random Password Generator,Password generate")

});

const copy = () => {
  navigator.clipboard.writeText(data.resultContent);
};

const reset = () => {
  data.resultContent = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">Random Password Generator</h1>
    <el-form>
      <el-form-item label="Number">
        <el-checkbox v-model="data.number">{{ data.chars.number }}</el-checkbox>
      </el-form-item>
      <el-form-item label="Lowercase Letters">
        <el-checkbox v-model="data.lowerLetter">{{
          data.chars.lowerLetter
        }}</el-checkbox>
      </el-form-item>
      <el-form-item label="Uppercase Letters">
        <el-checkbox v-model="data.upperLetter">{{
          data.chars.upperLetter
        }}</el-checkbox>
      </el-form-item>
      <el-form-item label="Special Characters">
        <el-checkbox v-model="data.specialChar">{{
          data.chars.specialChar
        }}</el-checkbox>
      </el-form-item>
      <el-form-item label="Password length">
        <el-input-number
          v-model="data.length"
          :min="1"
          :max="64"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="convert()"
        >generate random password ✅</el-button
      >
    </div>
    <el-input
      type="textarea"
      v-model="data.resultContent"
      :rows="4"
      placeholder="The output result is here 😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="Copied!"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >Copy</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >Clear</el-button
    >
    <div class="introduce">
      <h2>Why is Random Password Generator?🤔</h2>
      <p>
        The &quot;Random Password Generator&quot; is an online tool designed to
        generate strong and secure passwords. It is commonly used to create
        unique and unpredictable passwords that are difficult for others to
        guess or crack.
      </p>
      <p>
        The purpose of a random password generator is to enhance the security of
        accounts and sensitive information by generating passwords that are
        complex and difficult to guess. Here are some key features and benefits
        of a random password generator:
      </p>
      <ol>
        <li>
          Strong and Secure Passwords: The tool generates passwords that are
          composed of a combination of uppercase and lowercase letters, numbers,
          and special characters. This results in passwords that are more
          resilient to brute-force attacks or dictionary-based hacking attempts.
        </li>
        <li>
          Customization Options: The generator often allows users to customize
          the length and complexity of the generated passwords according to
          their specific requirements. This flexibility ensures that users can
          generate passwords that meet the password policies of different
          websites or systems.
        </li>
        <li>
          Convenience and Efficiency: Instead of spending time and effort trying
          to come up with a strong password manually, the random password
          generator simplifies the process by instantly providing a secure
          password with just a few clicks.
        </li>
        <li>
          Password Strength Indicators: Some generators provide indicators or
          ratings that assess the strength of the generated password. This helps
          users evaluate the effectiveness of the password and make adjustments
          if needed.
        </li>
        <li>
          Variety and Uniqueness: The generator ensures that each password
          generated is unique, reducing the risk of password reuse across
          different online accounts. This is crucial for maintaining security,
          as reusing passwords can expose multiple accounts if one of them gets
          compromised.
        </li>
      </ol>
      <p>
        When using a random password generator, it is important to remember that
        generated passwords should be securely stored and not shared or written
        down in easily accessible locations. Additionally, it is recommended to
        change passwords periodically and use different passwords for different
        online accounts to further enhance security.
      </p>
      <p>
        The &quot;Random Password Generator&quot; is a valuable tool
        for individuals and organizations seeking to improve their online
        security by creating strong, unique, and secure passwords.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
