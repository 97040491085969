<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";

onMounted(() => {
  document.title = "多久下班？";
  setMeta("description", "下班倒计时展示");
  setMeta("keywords", "倒计时,倒计时卡片展示,下班倒计时");
  // countDown("2024/3/12 18:00:00","#demo01 .day","#demo01 .hour","#demo01 .minute","#demo01 .second");
  setTime();
});
const time = ref("18:00");

const setTime = () => {
  if (timer) {
    clearInterval(timer);
  }
  const currentDate = new Date();

  // 设置小时、分钟和秒数为18:00:00
  currentDate.setHours(time.value.split(":")[0]);
  currentDate.setMinutes(time.value.split(":")[1]);
  currentDate.setSeconds(0);
  countDown(
    currentDate,
    null,
    "#demo02 .hour",
    "#demo02 .minute",
    "#demo02 .second"
  );
};

var timer;
var sys_second;

const countDown = (time, day_elem, hour_elem, minute_elem, second_elem) => {
  //if(typeof end_time == "string")

  var end_time = new Date(time).getTime(); //月份是实际月份-1
  //current_time = new Date().getTime(),

  sys_second = (end_time - new Date().getTime()) / 1000;

  function timeGo() {
    if (sys_second > 0) {
      sys_second -= 1;
      console.log(sys_second);

      var day = Math.floor(sys_second / 3600 / 24);

      var hour = Math.floor((sys_second / 3600) % 24);

      var minute = Math.floor((sys_second / 60) % 60);

      var second = Math.floor(sys_second % 60);

      day_elem && $(day_elem).text(day); //计算天

      $(hour_elem).text(hour < 10 ? "0" + hour : hour); //计算小时

      $(minute_elem).text(minute < 10 ? "0" + minute : minute); //计算分

      $(second_elem).text(second < 10 ? "0" + second : second); // 计算秒
    } else {
      clearInterval(timer);
    }
  }
  timeGo();
  timer = setInterval(timeGo, 1000);
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">多久下班？</h1>
    <el-form>
      <el-form-item label="选择下班时间：">
        <el-time-select
          v-model="time"
          @change="setTime"
          style="width: 240px"
          start="08:30"
          step="00:15"
          end="23:45"
          placeholder="Select time"
        />
      </el-form-item>
      <el-form-item label="距离下班还有：" style="margin-top: 20px;">
        <div >
          <!-- <div class="colockbox" id="demo01">
                <span class="day">-</span>
                <span class="hour">-</span>
                <span class="minute">-</span>
                <span class="second">-</span>
              </div> -->
          <div class="colockbox" id="demo02">
            <span class="hour">-</span>
            <span class="minute">-</span>
            <span class="second">-</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <h2 style="font-style: italic;">“到点就走，不要做资本家的狗。”</h2>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}

* {
  margin: 0;
  list-style-type: none;
}

a,
img {
  border: 0;
}

body {
  font: 12px/180% Arial, Helvetica, sans-serif, "宋体";
}

/* colockbox */

.colockbox {
  width: 283px;
  height: 76px;
  background: url("../../assets/img/colockbg.png") no-repeat;
}

.colockbox span {
  float: left;
  display: block;
  width: 58px;
  height: 48px;
  line-height: 48px;
  font-size: 26px;
  text-align: center;
  color: #ffffff;
  margin: 0 17px 0 0;
}

.colockbox span.second {
  margin: 0;
}

#demo02 {
  width: 208px;
  background-position: -75px 0;
}
</style>
