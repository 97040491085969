<script setup>
import { onMounted, reactive, ref } from "vue";
import { setMeta } from '@/utils/util'

const data = reactive({
  number: true,
  lowerLetter: true,
  upperLetter: true,
  specialChar: false,
  length: 16,
  chars: {
    number: "0123456789",
    lowerLetter: "abcdefghijklmnopqrstuvwxyz",
    upperLetter: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    specialChar: "~!@#$%^&*()[{]}-_=+|;:'\",<.>/?`",
  },
  resultContent: "",
});
const convert = () => {
  let e = ["number", "lowerLetter", "upperLetter", "specialChar"]
      .filter((e) => data[e])
      .map((e) => data.chars[e])
      .join(""),
    t = [],
    r = [],
    n = 0;
  for (let l = 0; l < data.length; l++) {
    do {
      n = Math.floor(Math.random() * e.length);
    } while (r.includes(n) && r.length < e.length);
    r.push(n), t.push(e[n]);
  }
  data.resultContent = t.join("");
};

onMounted(() => {
  document.title = "随机密码生成器";
  setMeta("description", "随机密码生成器,随机字符串生成器")
  setMeta("keywords", "随机密码生成器,密码生成")

});

const copy = () => {
  navigator.clipboard.writeText(data.resultContent);
};

const reset = () => {
  data.resultContent = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">随机密码生成器</h1>
    <el-form>
      <el-form-item label="数字">
        <el-checkbox v-model="data.number">{{ data.chars.number }}</el-checkbox>
      </el-form-item>
      <el-form-item label="小写字母">
        <el-checkbox v-model="data.lowerLetter">{{
          data.chars.lowerLetter
        }}</el-checkbox>
      </el-form-item>
      <el-form-item label="大写字母">
        <el-checkbox v-model="data.upperLetter">{{
          data.chars.upperLetter
        }}</el-checkbox>
      </el-form-item>
      <el-form-item label="特殊字符">
        <el-checkbox v-model="data.specialChar">{{
          data.chars.specialChar
        }}</el-checkbox>
      </el-form-item>
      <el-form-item label="密码长度">
        <el-input-number
          v-model="data.length"
          :min="1"
          :max="64"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="convert()"
        >生成随机密码 ✅</el-button
      >
    </div>
    <el-input
      type="textarea"
      v-model="data.resultContent"
      :rows="4"
      placeholder="输出结果在这里😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="复制成功！"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >复制</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >清除</el-button
    >
    <div class="introduce">
      
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
