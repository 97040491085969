export const list = [
    // {
    //     title: '',
    //     href: '',
    //     time: '2024-03-04'
    // },
    {
        title: 'Podwise ARR 正式突破 2 万美金',
        href: 'https://m.okjike.com/originalPosts/65e511e312ed2fda6849e701?s=eyJ1IjoiNTY3YTUwZDQ2ZWY4OWMxMjAwOGE3NTc1IiwiZCI6MX0%3D&utm_source=wechat_session',
        time: '2024-03-04'
    },
    {
        title: 'Sora变现的10种思路',
        href: 'https://svj4gxvm0v3.feishu.cn/docx/XfvJdIUQiogpw4xnO4acABfWnFe',
        time: '2024-02-18'
    },
    {
        title: '使用AI工具制作俄罗斯美女带货玩法',
        href: 'https://svj4gxvm0v3.feishu.cn/docx/KkKrdhYe5ox1NGxkxg7c8VcrnYL',
        time: '2024-02-06'
    },
    {
        title: '让文物跳科目三，照片跳舞AI工具：Anymate anyone',
        href: 'https://humanaigc.github.io/animate-anyone/',
        time: '2024-02-06'
    },
    {
        title: 'Stable Diffusion 生成数字人视频实操教程，解锁流量密码',
        href: 'https://j0oqwdylc47.feishu.cn/docx/Eg06dn8aSo97DaxJ2KkcuOlLnkg',
        time: '2024-02-06'
    },
    {
        title: '小红书商单项目复盘',
        href: 'https://svj4gxvm0v3.feishu.cn/docx/Jmuudb6LToujB9xC3nwcDzk3nmd',
        time: '2024-02-06'
    },
    {
        title: '图文带货喂饭级复盘',
        href: 'https://svj4gxvm0v3.feishu.cn/docx/PmSAduYrdoTyLKxLD43ca4WvnSd',
        time: '2024-02-06'
    },
    {
        title: '入门：AI学习路径',
        href: 'https://waytoagi.feishu.cn/wiki/RJofwtPcci6YMJkzBP2cRFFOnIR',
        time: '2024-02-06'
    },
    {
        title: '一文了解Prompts提示词（详细版）',
        href: 'https://waytoagi.feishu.cn/wiki/Q5mXww4rriujFFkFQOzc8uIsnah?table=tbldSgFt2xNUDNAz&view=vewo2g2ktO',
        time: '2024-02-06'
    },
    {
        title: 'ChatGPT是在做什么，为什么它有效',
        href: 'https://waytoagi.feishu.cn/wiki/LnMOwmKIwihcRakRalcczNgrnjf',
        time: '2024-02-06'
    },
    {
        title: '万物摩尔定律 by Sam Altman',
        href: 'https://waytoagi.feishu.cn/wiki/TVuEw0miti0ZuTkzsJsczEA8nVr',
        time: '2024-02-06'
    },
    {
        title: '关于工作效率 by Sam Altman',
        href: 'https://waytoagi.feishu.cn/wiki/JbquwzVbEimQG9kz4PhcYHxznif',
        time: '2024-02-06'
    },
    {
        title: '我希望有人早些告诉我这些 by Sam Altman',
        href: 'https://waytoagi.feishu.cn/wiki/WNc8wr0Tfi57OOk0uLbco1pEnof',
        time: '2024-02-06'
    },
    {
        title: '如何成就伟业 by Paul Graham',
        href: 'https://waytoagi.feishu.cn/wiki/DEZcwmBhxitfQqkHGfZc2ER8nGc',
        time: '2024-02-06'
    },
    {
        title: '思维链灵感来源于冥想',
        href: 'https://waytoagi.feishu.cn/wiki/GpwDwLAPiighW6k4NbTc3ot3nfV',
        time: '2024-02-06'
    },
]