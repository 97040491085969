<script setup>
import { ref, onMounted } from "vue";
import JsonViewer from "vue-json-viewer";
const code = ref("");
const jsonData = ref("");
import { setMeta } from "@/utils/util";
const errorShow = ref(false);

onMounted(() => {
  document.title = "JSON格式化";
  setMeta("description", "JSON编辑器,JSON格式化");
  setMeta("keywords", "JSON格式化,在线格式化");
});

const copy = () => {
  navigator.clipboard.writeText(jsonData.value);
};

const reset = () => {
  jsonData.value = "";
  code.value = "";
  errorShow.value = false;
};

const encode = () => {
  try {
    errorShow.value = false;
    jsonData.value = JSON.parse(code.value);
  } catch (error) {
    jsonData.value = code.value;
    errorShow.value = true;
  }
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">JSON格式化</h1>
    <el-input
      type="textarea"
      v-model="code"
      :rows="8"
      placeholder="请输入JSON字符串进行格式化🤔"
    ></el-input>
    <el-alert
      style="margin-top: 10px"
      type="error"
      effect="dark"
      v-show="errorShow"
      title="JSON格式有误！"
      show-icon
      :closable="false"
    ></el-alert>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">格式化👇</el-button>
    </div>
    <el-alert
      title="提示：按住 'Alt' 键并点击鼠标左键可以展开当前节点下的所有节点。"
      type="info"
      show-icon
      :closable="false"
    />
    <JsonViewer
      style="margin-top: 10px"
      :value="jsonData"
      boxed
      :expanded="true"
      :copyable="{ copyText: 'Copy', copiedText: 'Copied!' }"
    >
    </JsonViewer>

    <el-button style="margin-top: 10px" type="" @click="reset()"
      >Clear</el-button
    >
    <div class="introduce">
      <h2>什么是JSON？🤔</h2>
      <p>
        JSON（JavaScript Object
        Notation）是一种轻量级的数据交换格式，常用于数据传输和存储。它是一种基于文本的格式，易于人类阅读和编写，同时也便于机器解析和生成。
      </p>
      <p>
        JSON通常用作以键值对形式表示结构化数据的方式。它基于JavaScript编程语言的语法子集，但是与特定编程语言无关，可以与多种编程语言一起使用。
      </p>
      <p>
        在JSON中，数据被组织成对象和数组。对象是无序的键值对集合，其中每个键是一个字符串，每个值可以是字符串、数字、布尔值、null、对象或数组。数组是有序的值的集合，其中的值也可以是字符串、数字、布尔值、null、对象或数组。
      </p>
      <p>下面是一个简单的JSON对象示例：</p>
      <JsonViewer
        style="margin-top: 10px"
        :value="{ name: 'John Doe', age: 30, city: 'New York' }"
        boxed
        :expanded="true"
        :copyable="{ copyText: 'Copy', copiedText: 'Copied!' }"
      >
      </JsonViewer>
      <p>
        在这个示例中，我们有一个包含三个键值对的对象。键分别是 "name"、"age" 和
        "city"，对应的值分别是 "John Doe"、30 和 "New York"。
      </p>
      <p>
        JSON在Web开发和API集成中被广泛应用，用于不同系统之间的数据交换。大多数编程语言都支持JSON，提供了内置函数或库，使开发人员能够轻松解析JSON数据并将其转换为各自语言的本地数据结构。
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
::v-deep .jv-button {
  color: var(--el-color-primary) !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
::v-deep pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}
</style>
