<script setup>
import { ref, onMounted } from "vue";
const code = ref("");
import { useRoute  } from "vue-router";
import router from "@/router";
import { setMeta } from "@/utils/util";
const href = ref("");
const height = window.innerHeight - 60 - 68 - 100 - 20 -20 -10 - 10
const title = ref('')
onMounted(() => {
  const route = useRoute()
  console.log(route.query);
  title.value = document.title = route.query.title;
  href.value = route.query.href
  setMeta("description", "博客,技术文章,精选技术文章,技术答疑");
  setMeta("keywords", "博客,文章,技术,blog,技术答疑");
});
const goback = () => {
  router.back()
}
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px;">{{title}}</h1>
    <iframe :src="href" frameborder="0" width="100%" :height="height"></iframe>
  </div>
</template>
<style lang="scss" scoped>
    .box-card {
        margin-top: 10px;
        cursor: pointer;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .tag-type {
        margin-right: 10px;
    }
    .gray-text {
        color: #888888;
    }
</style>
