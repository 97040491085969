<script setup>
import { ref, onMounted } from "vue";
import QrcodeVue from "qrcode.vue";
import { setMeta } from "@/utils/util";

const code = ref("");
const result = ref("");
const size = ref(200);

onMounted(() => {
  document.title = "在线生成二维码";
  setMeta("description", "在线生成二维码,生成二维码");
  setMeta("keywords", "在线生成二维码,生成二维码");
});

const encode = () => {
  result.value = code.value;
};

const copy = () => {
  const link = document.createElement("a");
  link.download = `qrcode.png`;
  link.href = document.getElementById("qrcode").toDataURL();
  link.click();
};

const reset = () => {
  code.value = "";
  result.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">在线生成二维码</h1>
    <el-form>
      <el-form-item>
        <el-input
          v-model="code"
          type="textarea"
          :rows="5"
          placeholder="输入字符生成二维码，支持网址"
        />
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">生成👇</el-button>
    </div>

    <div class="wait mt-12" v-if="!result">输入内容生成二维码</div>
    <qrcode-vue
      v-else
      id="qrcode"
      class="mt-12"
      :value="result"
      :size="size"
      level="H"
    />
    <!-- <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="复制成功"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >复制结果</el-button
        >
      </template>
    </el-popover> -->
    <div>
      <el-button style="margin-top: 10px" type="primary" @click="copy()"
        >下载二维码图片💾</el-button
      >
      <el-button style="margin-top: 10px" type="" @click="reset()"
        >清除</el-button
      >
    </div>
    <div class="introduce">
      <h2>什么是二维码？🤔</h2>
      <p>
        二维码（Quick Response
        code）是一种二维条码，可以存储各种类型的信息，例如文本、URL、联系方式或其他数据。最初在日本为汽车工业开发，但由于其多功能性和易用性，二维码已经广泛流行起来。
      </p>
      <p>
        二维码由黑色方块组成，通常排列在白色背景上，形状一般为正方形。这些码可以使用智能手机或二维码阅读器进行扫描，阅读器利用设备的摄像头捕捉码的图像。一旦扫描，编码信息将被解码并由扫描设备或应用程序处理。
      </p>
      <p>
        二维码具有几个优点。与传统条码相比，它们可以存储大量数据，并且可以轻松生成并打印在各种表面上，如纸张、产品包装、海报或数字屏幕上。这使得它们适用于广泛的应用，包括营销、广告、售票、付款交易和库存管理等领域。
      </p>
      <p>
        要创建一个二维码，您可以使用上面的生成功能。您可以输入所需的文本信息并生成相应的二维码图像。然后可以共享或分发生成的二维码供他人扫描。
      </p>
      <p>
        二维码在移动技术方面越来越受欢迎，并广泛应用于各个行业。例如，在营销活动中，可以将二维码放置在印刷广告上，用户可以通过扫描码快速访问网站、促销活动或其他产品信息。在非接触式支付系统中，可以使用二维码来促进交易，允许用户扫描供应商设备或网站上显示的码。
      </p>
      <p>
        随着智能手机和二维码扫描应用程序的普及，二维码已成为快速轻松分享和访问信息的方便有效方式。它们已在广告、零售、交通运输、活动管理等领域找到应用。
      </p>
      <p>DZ：我测你们码（说藏话了😅）</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.mt-12 {
  margin-top: 12px;
}
.wait {
  width: 200px;
  height: 200px;
  border: dashed 1px #999;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #999;
}
</style>
