

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import './assets/main.css'
import './assets/element.scss'
import App from './App.vue'
import router from './router'

import VueCropper from "vue-cropper";
import 'vue-cropper/dist/index.css'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(VueCropper)
app.use(ElementPlus)
app.mount('#app')
