<script setup>
import { reactive, ref, onMounted } from "vue";
import { setMeta } from '@/utils/util'

onMounted(() => {
  document.title = "图片在线裁剪";
  setMeta("description", "在线裁剪本地图片,图片裁剪")
  setMeta("keywords", "在线裁剪,在线裁剪图片,图片裁剪")
})

const code = ref("");
const result = ref("");
const size = ref(200);
const cropper = ref();
const option = ref({
  img: require('../../assets/img/guagua.jpg'),
  full: false,
  outputType: "png",
  canMove: true,
  fixedBox: false,
  original: true,
  canMoveBox: true,
  centerBox: true,
  autoCrop: true,
  fixed: false,
  fixedNumber: [],
  // 只有自动截图开启 宽度高度才生效
  high: true,
  max: 99999,
});

const cropperKey = ref(Math.random())

let previews = ref({});

const encode = () => {
  result.value = code.value;
};

const reset = () => {
  code.value = "";
  result.value = "";
};

const realTime = (data) => {
  previews.value = data;
  console.log(data);
};

const down = () => {
  // event.preventDefault()
  var aLink = document.createElement("a");
  aLink.download = "裁剪结果";
  // 输出
  cropper.value.getCropBlob((data) => {
    aLink.href = window.URL.createObjectURL(data);
    aLink.click();
  });
};

const free = () => {
  option.value.fixed = false;
  cropperKey.value = Math.random()
};

const to169 = () => {
  option.value.fixed = true;
  option.value.fixedNumber = [16, 9];
  console.log(option.value);
  cropperKey.value = Math.random()
};

const to11 = () => {
  option.value.fixed = true;
  option.value.fixedNumber = [1, 1];
  console.log(option.value.fixedNumber);
  cropperKey.value = Math.random()
};

const imgChange = (uploadFile, uploadFiles) => {
  console.log(uploadFile);
  option.value.img = URL.createObjectURL(uploadFile.raw)
}
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">图片在线裁剪</h1>
    <div class="cropper-area">
      <VueCropper
        :key="cropperKey"
        ref="cropper"
        :img="option.img"
        :output-size="option.size"
        :output-type="option.outputType"
        :info="true"
        :full="option.full"
        :fixed="option.fixed"
        :fixed-number="option.fixedNumber"
        :can-move="option.canMove"
        :can-move-box="option.canMoveBox"
        :fixed-box="option.fixedBox"
        :original="option.original"
        :auto-crop="option.autoCrop"
        :auto-crop-width="option.autoCropWidth"
        :auto-crop-height="option.autoCropHeight"
        :center-box="option.centerBox"
        @real-time="realTime"
        :high="option.high"
        :max-img-size="option.max"
        mode="contain"
      ></VueCropper>
    </div>

    <div class="btn-area">
      <el-upload
        ref="uploadRef"
        class="upload-demo"
        action=""
        :auto-upload="false"
        :on-change="imgChange"
        :show-file-list="false"
      >
        <template #trigger>
          <el-button type="primary" style="margin-right: 12px;">选择图片</el-button>
        </template>
      </el-upload>
      <el-button type="primary" @click="free">自由裁剪</el-button>
      <el-button type="primary" @click="to169">16:9</el-button>
      <el-button type="primary" @click="to11">1:1</el-button>
    </div>

    <h2 style="margin: 10px 0">实时预览：</h2>

    <div
      class="wait"
      :style="{
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        padding: '10px',
      }"
    >
      <div :style="previews.div">
        <img :src="previews.url" :style="previews.img" />
      </div>
    </div>
    <div>
      <el-button style="margin-top: 10px" type="primary" @click="down()"
        >下载结果</el-button
      >
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.mt-12 {
  margin-top: 12px;
}
.wait {
  width: 200px;
  height: 200px;
  border: dashed 1px #999;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
.cropper-area {
  width: 700px;
  height: 400px;
}
</style>
