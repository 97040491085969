<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import { ElMessage } from 'element-plus'
import { faker } from '@faker-js/faker';
onMounted(() => {
  document.title = "英文名字生成器";
  setMeta("description", "英文名字生成器,随机英文名字生成器,批量生成英文名");
  setMeta("keywords", "英文名字生成器,随机英文名字生成器,批量生成英文名");
});

const randomNames = ref([])
const result = ref('')
const num = ref(500)
const sex = ref('')
const splitBy = ref('n')

const generate = () => {
    randomNames.value = []
    for (let i = 0; i < num.value; i++) {
        randomNames.value.push(faker.person.fullName(sex.value))
    }
    result.value = randomNames.value.join(splitBy.value === 'n'? '\n': splitBy.value)
}

const copy = () => {
  navigator.clipboard.writeText(result.value);
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">英文名字生成器</h1>
    <el-form>
        <el-form-item label="生成数量：">
            <el-input-number v-model="num" :min="1" :max="9999" @change="handleChange" />
        </el-form-item>
        <el-form-item label="性别：">
            <el-radio-group v-model="sex">
                <el-radio label="">全部</el-radio>
                <el-radio label="male">男</el-radio>
                <el-radio label="female">女</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="分隔符：">
            <el-radio-group v-model="splitBy">
                <el-radio label="n">换行</el-radio>
                <el-radio label=", ">逗号</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <div class="btn-area">
        <el-button type="primary" @click="generate">生成</el-button>
    </div>
    <el-input
      type="textarea"
      v-model="result"
      :rows="10"
      placeholder="结果在这里 😀"
    ></el-input>
    <el-popover
          placement="top"
          :auto-close="1000"
          trigger="click"
          popper-style="text-align: center;min-width:100px;width: 100px"
          content="复制成功！"
        >
          <template #reference>
            <el-button type="primary" class="mt-12" @click="copy()"
              >复制</el-button
            >
          </template>
        </el-popover>
    <div class="introduce">
        <h2>工具简介：</h2>
        <p>在线英文名随机生成器，可以快速批量生成指定数量的英文名，支持性别设置，生成男性英文姓名或女性英文姓名。</p>
        <p>填写需要生成的数量（1-9999）以及性别，点击生成即可，生成后将会给出姓名列表以及展示列表，您也可以一键复制生成的英文姓名。</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
.introduce {
    // text-indent: 28px;
}
.mt-12 {
  margin-top: 12px;
}
</style>
