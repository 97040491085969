<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import { ElMessage } from 'element-plus'
onMounted(() => {
  document.title = "IP归属地查询";
  setMeta("description", "IP地址查询");
  setMeta("keywords", "IP地址查询,签名图片,IP签名档,个性动态签名IP");
  getLocalIp()
});
const myIp = ref('')
const url = ref('');
const ipResult = ref({})

const getLocalIp = () => {
    fetch('https://api.ipify.org/?format=json').then(res=>{
        res.json().then(result=>{
            console.log(result.ip);
            myIp.value = result.ip
        })
    })
}

const encode = () => {
    fetch(`http://ip-api.com/json/${url.value}`).then(res1=>{
        res1.json().then(result=>{
            console.log(result);
            if (result.status === 'success') {
                ipResult.value = result
            } else {
                ElMessage.error('请检查输入的地址')
            }
        });
    })
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">IP归属地查询</h1>
    <el-form>
        <el-form-item label="你的外网IP地址是：">
            {{ myIp }}
        </el-form-item>
        <el-form-item label="请输入网站域名或者IP：">
            <el-input v-model="url" style="width: 300px" placeholder="不填写将默认查询本机地址" clearable></el-input>
            <el-button style="margin-left: 10px" type="primary" @click="encode()">查询</el-button>
        </el-form-item>
    </el-form>
    <div class="btn-area">
      
    </div>
    <el-form :model="ipResult">
        <el-form-item label="IP地址：">
            <div>
                {{ ipResult.query || '-' }}
            </div>
        </el-form-item>
        <el-form-item label="归属地：">
            <div v-if="ipResult.country">
                {{ ipResult.city + ' ' + ipResult.regionName + ' ' + ipResult.country }}
            </div>
            <div v-else>-</div>
        </el-form-item>
        <el-form-item label="所属组织：">
            <div>
                {{ ipResult.org || '-' }}
            </div>
        </el-form-item>
        <el-form-item label="经纬度：">
            <div v-if="ipResult.lon">
                {{ ipResult.lon + ', ' + ipResult.lat }}
            </div>
            <div v-else>-</div>
        </el-form-item>
    </el-form>
    <div class="introduce">
        <p>IP归属地查询工具，提供全球IP归属地查询。</p>

        <p>IP归属地查询的原理是基于IP地址与地理位置之间的映射关系。当你通过IP归属地查询服务查询某个IP地址的归属地时，其实是通过查找包含IP地址与地理位置之间映射关系的数据库进行查询。</p>

        <p>这些IP归属地查询服务通常基于两种主要的数据来源来构建其数据库：</p>

        <p>IP地址分配机构：IP地址分配机构负责将IP地址块分配给不同的互联网服务提供商和组织。这些机构维护了记录IP地址分配情况的数据库，包含了IP地址块与分配给不同地理区域的关联信息。IP归属地查询服务可以从这些机构获取IP地址分配信息，进而将IP地址与地理位置进行关联。</p>

        <p>数据采集和整理：除了IP地址分配机构，还有一些机构通过不断的数据采集和整理来构建IP归属地数据库。这些机构可能通过各种方式，如爬取公开的网络信息、与互联网服务提供商合作等，收集IP地址与地理位置之间的关联信息。然后，他们将这些信息整理成数据库，用于提供IP归属地查询服务。</p>

        <p>当你查询某个IP地址的归属地时，IP归属地查询服务会从其数据库中查找与该IP地址相关的地理位置信息，并返回给你。这可以帮助你确定IP地址所属的国家、地区、城市或网络服务提供商等信息。</p>

        <p>需要注意的是，IP归属地查询并非100%准确，因为IP地址的分配和使用可能会发生变化，而且数据库的更新可能会有一定的延迟。因此，查询结果可能会稍有偏差或不准确。</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
.introduce {
    // text-indent: 28px;
}
</style>
