<script setup>
import { onMounted, ref } from "vue";
import { RouterLink, RouterView } from "vue-router";
import { Search, ArrowDown } from "@element-plus/icons-vue";
import router from "@/router";
import { searchKeyStore, langStore } from "./stores/counter";
const activeIndex2 = ref("/");
const searchKey = ref("");
const lang = langStore()

onMounted(() => {
  // var userLanguage = navigator.language;
  // console.log(userLanguage);
  // if (userLanguage === 'zh-CN') {
  //   lang.changeLang('简体中文')
  // } else {
  //   lang.changeLang('English')
  // }
  console.log(window.location);
  const pathName = window.location.pathname
  if (pathName.indexOf('/zh') > -1) {
    lang.changeLang('简体中文')
  } else {
    lang.changeLang('English')
  }
})

const gohome = () => {
  if (lang.lang === 'English') {
    router.push({
      path: "/",
    });
  } else {
    router.push({
      path: "/zh",
    });
  }
  
};

const goBlog = () => {
  if (lang.lang === 'English') {
    router.push({
      path: "/blog",
    });
  } else {
    router.push({
      path: "/zh/blog",
    });
  }
}

const searchList = () => {
  const store = searchKeyStore();
  store.changeSearchKey(searchKey.value);
  gohome();
};

const langSelect = (val) => {
  const origin = window.location.origin
  let pathname = window.location.pathname
  let path = ''
  if (val === 'English') {
    pathname = pathname.replace('/zh', '')
    path = origin + pathname
  } else {
    pathname = '/zh' + pathname
    path = origin + pathname
  }
  window.open(path)
}
</script>

<template>
  <div>
    <el-menu
      router
      :default-active="activeIndex2"
      class="el-menu-demo"
      mode="horizontal"
    >
      <div class="logo" @click="gohome">
        <img class="logo-icon" src="@/assets/img/tools.png" alt="" />
        <span class="colorful">AnyTools </span>&nbsp; 
        <span v-if="lang.lang === 'English'">-Sleek and user-friendly developer tools.</span>
        <span v-if="lang.lang === '简体中文'">-简洁、友好的开发者在线工具</span>
      </div>
      <div v-if="lang.lang === '简体中文'" class="blog"><el-link :underline="false" @click="goBlog">博客</el-link></div>
      <div v-if="lang.lang === 'English'" class="blog"><el-link :underline="false" @click="goBlog">Blog</el-link></div>
      <div class="search in-header" v-if="lang.lang === 'English'">
        <el-input
          v-model="searchKey"
          placeholder="Search Tools"
          :prefix-icon="Search"
          @keydown.enter="searchList"
          clearable
        >
          <template #append>
            <el-button @click="searchList" :icon="Search"></el-button>
          </template>
        </el-input>
      </div>
      <div class="search in-header" v-if="lang.lang === '简体中文'">
        <el-input
          v-model="searchKey"
          placeholder="搜索小工具"
          :prefix-icon="Search"
          @keydown.enter="searchList"
          clearable
        >
          <template #append>
            <el-button @click="searchList" :icon="Search"></el-button>
          </template>
        </el-input>
      </div>
      <div class="right in-header">
        <el-dropdown @command="langSelect">
          <div class="el-dropdown-link">
            <img width="30" height="30" src="@/assets/img/lang.png" />
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </div>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="English" :disabled="lang.lang === 'English'">English↗</el-dropdown-item>
              <el-dropdown-item command="简体中文" :disabled="lang.lang === '简体中文'">简体中文↗</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-menu>
    <!-- <img src="./assets/img/cat.png" class="hello-cat" alt=""> -->
    <div class="body">
      <RouterView />
      <el-backtop :right="100" :bottom="100"> </el-backtop>
    </div>
    <div class="bottom">
      Made by <el-button type="text" class="colorful">@NickyXing</el-button> with love ♥
    </div>
  </div>
</template>

<style scoped lang="scss">
.logo {
  /* width: 360px; */
  /* height: 60px; */
  line-height: 59px;
  text-align: center;
  color: #333;
  /* color: var(--el-color-primary); */
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.logo-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.logo:hover {
  cursor: pointer;
}
.in-header {
  height: 59px;
  line-height: 59px;
}
.search {
  position: absolute;
  right: 0;
  width: 220px;
  transform: translateX(-50%);
}
.blog {
  position: absolute;
  right: 60px;
  width: 220px;
  transform: translateX(-50%);
  line-height: 59px;
  cursor: pointer;
}
.right {
  position: absolute;
  right: 10px;
  width: 50px;
  top: 15px;
}
.body {
  max-width: 1190px;
  margin: 0 auto;
  padding: 100px 20px 72px 20px;
}
.bottom {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  line-height: 20px;
  position: fixed;
  bottom: 0px;
  background: #fff;
  /* border-top: solid #f5f5f5 1px; */
  box-shadow: var(--el-box-shadow-light);
}
.el-menu-demo {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.hello-cat {
  position: fixed;
  top: 59px;
  right: 30px;
  width: 150px;
  height: 150px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
}
.el-button--primary {
  color: #FFF !important;
}
</style>
<style>
.el-menu {
  background: transparent;
  background-image: radial-gradient(transparent 1px,#FFFFFF 1px);
  background-size: 4px 4px;
  backdrop-filter: saturate(50%) blur(4px);
  -webkit-backdrop-filter: saturate(50%) blur(4px);
}
</style>