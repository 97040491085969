<script setup>
import { setMeta } from '@/utils/util'
import { ref, onMounted } from "vue";
onMounted(() => {
  document.title = "UUID Generator";
  setMeta("description", "UUID generator, batch UUID generation")
  setMeta("keywords", "UUID generator,batch UUID generation,UUID generate")
})
const num = ref(1);
const result = ref("");

const isHeng = ref(true);

const encode = () => {
  let arr = [];
  for (let i = 0; i < num.value; i++) {
    arr.push(generateUUID());
  }
  result.value = arr.join("\n");
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};

const generateUUID = () => {
  let d = new Date().getTime();
  let str = isHeng.value
    ? "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    : "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx";
  let uuid = str.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const reset = () => {
  result.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">UUID Generator</h1>
    <el-form>
      <el-form-item label="Generate quantity">
        <el-input-number v-model="num" :min="1" :max="500" />
      </el-form-item>
      <el-form-item label="Whether to include hyphens">
        <el-checkbox v-model="isHeng"></el-checkbox>
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">Generate 👇</el-button>
    </div>
    <el-input
      type="textarea"
      v-model="result"
      :rows="8"
      placeholder="The output result is here 😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="Copied!"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >Copy</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >Clear</el-button
    >
    <div class="introduce">
      <h2>What is UUID?🤔</h2>
      <p>
        A UUID (Universally Unique Identifier) is a standardized identifier that
        is designed to be unique across all devices and time. It is a 128-bit
        value typically represented as a string of alphanumeric characters,
        separated by hyphens to enhance readability.
      </p>
      <p>
        UUIDs are commonly used in various systems and applications to uniquely
        identify resources, objects, or entities. Unlike other types of
        identifiers such as sequential numbers or database auto-increment
        values, UUIDs offer a higher level of uniqueness and can be generated
        independently, without requiring centralized coordination.
      </p>
      <p>
        The structure of a UUID follows a specific format. It consists of five
        groups of characters, separated by hyphens, with the following pattern:
      </p>
      <p>xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx</p>
      <ul>
        <li>
          The first group represents a 32-bit hexadecimal value, often derived
          from the current system time or a random number generator.
        </li>
        <li>
          The second group represents a version number that specifies the UUID
          variant being used. For example, version 4 (randomly generated) and
          version 5 (based on a hash of a namespace identifier and a name) are
          commonly used.
        </li>
        <li>
          The third group represents a variant number, indicating the layout and
          interpretation of the UUID. The most common variant is &quot;variant
          2,&quot; which is defined by RFC 4122.
        </li>
        <li>
          The fourth group is a reserved value called the &quot;clock
          sequence,&quot; which helps ensure uniqueness in scenarios where UUIDs
          are generated rapidly.
        </li>
        <li>
          The fifth group represents a 48-bit value, often derived from the
          network MAC address of the generating device.
        </li>
      </ul>
      <p>An example of a UUID:</p>
      <p>550e8400-e29b-41d4-a716-446655440000</p>
      <p>
        UUIDs are widely used in various domains, including database systems,
        distributed systems, messaging protocols, and web applications. They
        provide a reliable way to uniquely identify resources without the need
        for centralized coordination or reliance on sequential numbering
        systems.
      </p>
      <p>
        UUIDs offer several advantages, such as uniqueness, low collision
        probability, and the ability to generate them independently. However,
        they are relatively longer and less human-readable compared to other
        identifier formats. Nonetheless, their benefits make them a popular
        choice for scenarios that require globally unique identifiers.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
