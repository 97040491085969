<script setup>
import { onMounted, ref, watchEffect } from "vue";
import router from "@/router";
import { setMeta } from '@/utils/util'
import { searchKeyStore } from "@/stores/counter";
import FriendLink from "@/components/FriendLink.vue";

const list = [
  {
    title: "IP Address Lookup",
    describe: "IP Address Lookup Tool, providing global IP Address Lookup",
    onlineUrl: window.location.origin + "/ip",
    router: "/ip",
    icon: require('../assets/img/search.png'),
  },
  {
    title: "Base64 encoding & decoding",
    describe:
      "Encoding and decoding characters in Base64, And Converting images to Base64 format.",
    onlineUrl: window.location.href + "base64",
    router: "/base64",
    icon: require('../assets/img/code.png'),
  },
  {
    title: "JSON Formatting",
    describe:
      "JSON Content Formatting and Parsing, Displayed by JSON Hierarchy.",
    onlineUrl: window.location.href + "json",
    router: "/json",
    icon: require('../assets/img/keyboard.png'),
  },
  {
    title: "Unix Timestamp Converter",
    describe: "Converting Unix Timestamp to date.",
    onlineUrl: window.location.href + "timestamp",
    router: "/timestamp",
    icon: require('../assets/img/clock.png'),
  },
  {
    title: "UUID Generator",
    describe: "Batch UUID Generation.",
    onlineUrl: window.location.href + "uuid",
    router: "/uuid",
    icon: require('../assets/img/random.png'),
  },
  {
    title: "Online QR Code Generator",
    describe:
      "Online QR code generator allows you to generate QR codes for various purposes, including website URLs.",
    onlineUrl: window.location.href + "qrcode",
    router: "/qrcode",
    icon: require('../assets/img/qr.png'),
  },
  {
    title: "Online Image Cropping",
    describe: "Crop Your Image Online.",
    onlineUrl: window.location.href + "cropper",
    router: "/cropper",
    icon: require('../assets/img/crop.png'),
  },
  {
    title: "URL Encoding & Decoding",
    describe: "Encode to URL-encoded format or decode from it.",
    onlineUrl: window.location.href + "urlencode",
    router: "/urlencode",
    icon: require('../assets/img/pc.png'),
  },
  {
    title: "Random Password Generator",
    describe: "Generate Random Password Based on Rules",
    onlineUrl: window.location.href + "password",
    router: "/password",
    icon: require('../assets/img/random.png'),
  },
  {
    title: "Online Markdown Editor",
    describe: "Lightweight, powerful, secure, locally stored data, with support for exporting to PDF/MD/HTML.",
    onlineUrl: window.location.origin + "/markdown",
    router: "/markdown",
    icon: require('../assets/img/md.png'),
  },
  {
    title: "Excel to JSON",
    describe: "Convert excel file to formatted json",
    onlineUrl: window.location.origin + "/excel2json",
    router: "/excel2json",
    icon: require('../assets/img/keyboard.png'),
  },
  {
    title: "Excel Online Editor",
    describe: "👊Powerful Online Excel Editor,no need for further explanation.",
    onlineUrl: window.location.origin + "/excel",
    router: "/excel",
    icon: require('../assets/img/excel.png'),
  },
  {
    title: "English Name Generator",
    describe: "Generate random English names in bulk.",
    onlineUrl: window.location.origin + "/randomName",
    router: "/randomName",
    icon: require('../assets/img/random.png'),
  },
];

const searchList = ref(list);
const toTool = (url) => {
  router.push({
    path: url,
  });
};

const store = searchKeyStore();

const search = (searchKey) => {
  if (searchKey) {
    searchList.value = [];
    for (const item of list) {
      if (
        item.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
        item.describe.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      ) {
        searchList.value.push(item);
      }
    }
  } else {
    searchList.value = list;
  }
};

const delay = 50; // 每个字母的延迟时间

const typeWriter = (text, index) => {
  if (index < text.length) {
    if (document.getElementById("typing-text")) {
      document.getElementById("typing-text").innerHTML += text.charAt(index);
      index++;
      setTimeout(function () {
        typeWriter(text, index);
      }, delay);
    }
  }
};

onMounted(() => {
  document.title = "AnyTools -Sleek and user-friendly developer tools.";
  setMeta("description", "Online Tools,Developer Tools,Base64 Encoding/Decoding,JSON Formatting,QR Code Generator,Image Compression,Image Cropping,URL Encode/Decode")
  setMeta("keywords", "Online Tools,Developer Tools,Base64 Encoding/Decoding,JSON Formatting,QR Code Generator,Image Compression,Image Cropping,URL Encode/Decode")

  typeWriter("To do a good job, one must first sharpen one's tools.", 0);
  console.log(store.searchKey);
  search(store.searchKey || "");
  
});

watchEffect(() => {
  console.log(store.searchKey);
  search(store.searchKey || "");
});
</script>

<template>
  <h1 class="name colorful">
    <img class="logo-icon" src="@/assets/img/tools.png" alt="" />AnyTools
  </h1>
  <h2 class="big-title colorful" id="typing-text"></h2>
  <div class="item-list">
    <div
      class="item"
      v-for="(item, index) in searchList"
      :key="index"
      @click="toTool(item.router)"
    >
      <div class="item-inner">
        <div class="item-title">
          <div>
            <img class="img-icon" :src="item.icon" alt="" />
            <!-- <div class="img-icon">{{ item.icon }}</div> -->
          </div>
          <div class="title">
            <span class="title-text" @click="toTool(item.router)">{{
              item.title
            }}</span>
            <div class="describe">
              {{ item.describe }}
            </div>
          </div>
        </div>
        <div class="item-content">
          <el-link type="primary" @click="toTool(item.router)">{{
            item.onlineUrl
          }}</el-link>
          <el-button type="text" @click="toTool(item.router)">Enter</el-button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="more">
    More features are currently under development. Stay tuned! 💻
  </div> -->
  <FriendLink lang="en"></FriendLink>
</template>

<style lang="scss" scoped>
.name {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
.logo-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  top: 9px;
}
.big-title {
  // font-size: 40px;
  text-align: center;
  margin: 0px 0 40px 0;
  color: #999;
  // text-decoration: underline;
}
.item-list {
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  justify-content: space-evenly;
}
.item {
  border: 1px solid #e4e7ed;
  position: static;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-in-out;
}
.item:hover {
  cursor: pointer;
  box-shadow: var(--el-box-shadow-light);
}
.item-inner {
  padding: 10px 15px;
}

.item-title {
  display: flex;

  .title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 15px;
  }
  .title-text {
    font-weight: bold;
    // color: var(--el-color-primary);
    color: #333;
  }
  .title-text:hover {
    cursor: pointer;
  }
  .describe {
    font-size: 12px;
    color: #999;
    height: 60px;
    overflow: hidden;
  }
}

.item-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #999;
}

.img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.img-icon {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  // background: var(--el-color-primary);
  // background: -webkit-linear-gradient(315deg, var(--el-color-primary) 25%, #42d392);
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-shadow: 2px 2px 2px #000;
  box-shadow: #ccc 0px 0px 6px 1px
}
.more {
  text-align: center;
  margin-top: 30px;
  color: #999;
}
</style>
