export function setMeta(name, value) {
  let metaElement = document.querySelector(`meta[name="${name}"]`);
  if (metaElement) {
    metaElement.setAttribute("content", value);
  } else {
    metaElement = document.createElement("meta");
    metaElement.setAttribute("name", name);
    metaElement.setAttribute("content", value);
    document.head.appendChild(metaElement);
  }
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function padZero(num) {
  return num.toString().padStart(2, '0');
}
