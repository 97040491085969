<template>
  <div class="friend-link" v-if="linkList.length > 0">
    <span v-if="lang==='en'">Reciprocal link: </span
    >
    <span v-if="lang==='zh'">友情链接：</span
    ><a
      class="el-tag el-tag--light colorful"
      :href="item.link"
      target="_blank"
      v-for="(item, index) in linkList"
      :key="index"
      >{{ item.name }}</a
    >
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
defineProps({
    lang: String
})
const linkList = ref([
  {
    name: "SoraHub",
    link: "https://sorahub.co",
  },
]);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.friend-link {
  width: 100%;
  padding: 20px 0;
  a {
    text-decoration: none;
    margin-right: 12px;
  }
}
</style>
