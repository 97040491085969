<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import ExcelJS from "exceljs";
import JsonViewer from "vue-json-viewer";
import { UploadFilled } from "@element-plus/icons-vue";
onMounted(() => {
  document.title = "Convert Excel to JSON";
  setMeta("description", "Convert Excel to json online");
  setMeta("keywords", "excel to json,exceltojson,excel convert,excel2json");
});

let file = "";
const jsonShowData = ref("");

const handleFileUpload = (uploadFile, uploadFiles) => {
  console.log(uploadFile);
  file = uploadFile.raw;
  convertToJSON();
};
const convertToJSON = () => {
  const fileReader = new FileReader();
  fileReader.onload = (event) => {
    const data = new Uint8Array(event.target.result);
    const workbook = new ExcelJS.Workbook();
    workbook.xlsx.load(data).then(() => {
      const jsonData = {};
      workbook.eachSheet((worksheet, sheetId) => {
        const sheetData = [];
        const headers = [];
        worksheet.getRow(1).eachCell((cell) => {
          headers.push(cell.value);
        });
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            // 跳过标题行
            const rowData = {};
            row.eachCell((cell, colNumber) => {
              const header = headers[colNumber - 1] || `column${colNumber}`;
              rowData[header] = cell.value;
            });
            sheetData.push(rowData);
          }
        });
        jsonData[worksheet.name] = sheetData;
      });
      console.log(jsonData); // 在控制台打印转换后的JSON数据
      jsonShowData.value = jsonData;
    });
  };
  fileReader.readAsArrayBuffer(file);
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">Convert Excel to JSON</h1>
    <div>
      <el-upload
        class="drop-upload"
        drag
        action=""
        :auto-upload="false"
        accept=".xlsx, .xls"
        :on-change="handleFileUpload"
      >
        <div class="el-upload__text">
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="">Drop file here or <em>click to upload</em></div>
        </div>

        <template #tip>
          <div class="el-upload__tip">
            Accepting Excel format files, if the document content does not conform to the JSON rules, the parsing result may fail or become distorted.
          </div>
        </template>
      </el-upload>
    </div>
    <JsonViewer
      style="margin-top: 10px"
      :value="jsonShowData"
      boxed
      :preview-mode="true"
      :expanded="true"
      :copyable="{ copyText: 'Copy', copiedText: 'Copied!' }"
    >
    </JsonViewer>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
.el-upload__text {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
