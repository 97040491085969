<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { faker } from "@faker-js/faker";
onMounted(() => {
  document.title = "English Names Generator";
  setMeta("description", "English Names Generator");
  setMeta("keywords", "English Names Generator");
});

const randomNames = ref([]);
const result = ref("");
const num = ref(500);
const sex = ref("");
const splitBy = ref("n");

const generate = () => {
  randomNames.value = [];
  for (let i = 0; i < num.value; i++) {
    randomNames.value.push(faker.person.fullName(sex.value));
  }
  result.value = randomNames.value.join(
    splitBy.value === "n" ? "\n" : splitBy.value
  );
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">English Names Generator</h1>
    <el-form>
      <el-form-item label="Number：">
        <el-input-number
          v-model="num"
          :min="1"
          :max="9999"
          @change="handleChange"
        />
      </el-form-item>
      <el-form-item label="性别：">
        <el-radio-group v-model="sex">
          <el-radio label="">All</el-radio>
          <el-radio label="male">male</el-radio>
          <el-radio label="female">female</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Separator: ">
        <el-radio-group v-model="splitBy">
          <el-radio label="n">Line break</el-radio>
          <el-radio label=", ">Comma</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="generate">Generate</el-button>
    </div>
    <el-input
      type="textarea"
      v-model="result"
      :rows="10"
      placeholder="The result is here 😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="Copied!"
    >
      <template #reference>
        <el-button type="primary" class="mt-12" @click="copy()">Copy</el-button>
      </template>
    </el-popover>
    <div class="introduce">
      <div class="introduce">
        <h2>Tool Introduction:</h2>
        <p>
          Online random English name generator that can quickly generate a
          specified quantity of English names in bulk. It supports gender
          selection to generate male or female English names.
        </p>
        <p>
          Specify the desired quantity (1-9999) and gender, then click on the
          generate button. After generating, a list of names will be provided
          along with a display list. You can also copy the generated English
          names with a single click.
        </p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
.introduce {
  // text-indent: 28px;
}
.mt-12 {
  margin-top: 12px;
}
</style>
