<script setup>
import { ref, onMounted } from "vue";
import QrcodeVue from "qrcode.vue";
import { setMeta } from '@/utils/util'

const code = ref("");
const result = ref("");
const size = ref(200);

onMounted(() => {
  document.title = "Online QR Code Generator";
  setMeta("description", "Online QR Code Generator,QR code creation")
  setMeta("keywords", "QR code,qrcode,QR code creation,QR code generation,WeChat QR code")
})

const encode = () => {
  result.value = code.value;
};

const copy = () => {
  const link = document.createElement("a");
  link.download = `qrcode.png`;
  link.href = document.getElementById("qrcode").toDataURL();
  link.click();
};

const reset = () => {
  code.value = "";
  result.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">Online QR Code Generator</h1>
    <el-form>
      <el-form-item>
        <el-input
          v-model="code"
          type="textarea"
          :rows="5"
          placeholder="Please enter the content you want to generate a QR code for, supporting various types of text and URLs."
        />
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">Generate 👇</el-button>
    </div>

    <div class="wait mt-12" v-if="!result">
      Please enter the content to generate a QR code.
    </div>
    <qrcode-vue
      v-else
      id="qrcode"
      class="mt-12"
      :value="result"
      :size="size"
      level="H"
    />
    <!-- <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="复制成功"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >复制结果</el-button
        >
      </template>
    </el-popover> -->
    <div>
      <el-button style="margin-top: 10px" type="primary" @click="copy()"
        >Download the QR code image 💾</el-button
      >
      <el-button style="margin-top: 10px" type="" @click="reset()"
        >Clear</el-button
      >
    </div>
    <div class="introduce">
      <h2>What is QR code?🤔</h2>
      <p>
        A QR code (Quick Response code) is a two-dimensional barcode that can
        store various types of information, such as text, URLs, contact details,
        or other data. It was originally developed in Japan for the automotive
        industry but has since gained widespread popularity due to its
        versatility and ease of use.
      </p>
      <p>
        QR codes consist of black squares arranged on a white background,
        typically in a square shape. These codes can be scanned using a
        smartphone or a QR code reader, which utilizes the device's camera to
        capture the code's image. Once scanned, the encoded information is
        decoded and processed by the scanning device or application.
      </p>
      <p>
        QR codes offer several advantages. They can store large amounts of data
        compared to traditional barcodes, and they can be easily generated and
        printed on various surfaces, such as paper, product packaging, posters,
        or digital screens. This makes them suitable for a wide range of
        applications, including marketing, advertising, ticketing, payment
        transactions, and inventory management.
      </p>
      <p>
        To create a QR code, you can use the aforementioned generation function.
        You can input the desired text information and generate the
        corresponding QR code image. The generated QR code can then be shared or
        distributed for scanning by others.
      </p>
      <p>
        QR codes have become increasingly popular in mobile technology and are
        widely used in various industries. For instance, in marketing campaigns,
        QR codes can be placed on print advertisements, enabling users to
        quickly access websites, promotions, or additional product information
        by scanning the code. In contactless payment systems, QR codes can be
        used to facilitate transactions by allowing users to scan a code
        displayed on a vendor's device or website.
      </p>
      <p>
        With the widespread availability of smartphones and QR code scanning
        applications, QR codes have become a convenient and efficient way to
        share and access information quickly and easily. They have found
        applications in areas such as advertising, retail, transportation, event
        management, and more.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.mt-12 {
  margin-top: 12px;
}
.wait {
  width: 200px;
  height: 200px;
  border: dashed 1px #999;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #999;
}
</style>
