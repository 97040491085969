<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import { ElMessage } from 'element-plus'
onMounted(() => {
  document.title = "IP Address Lookup";
  setMeta("description", "IP Address Lookup");
  setMeta("keywords", "IP Address Lookup");
  getLocalIp()
});
const myIp = ref('')
const url = ref('');
const ipResult = ref({})

const getLocalIp = () => {
    fetch('https://api.ipify.org/?format=json').then(res=>{
        res.json().then(result=>{
            console.log(result.ip);
            myIp.value = result.ip
        })
    })
}

const encode = () => {
    fetch(`http://ip-api.com/json/${url.value}`).then(res1=>{
        res1.json().then(result=>{
            console.log(result);
            if (result.status === 'success') {
                ipResult.value = result
            } else {
                ElMessage.error('Please check the entered address')
            }
        });
    })
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">IP Address Lookup</h1>
    <el-form>
        <el-form-item label="Your public IP address is:">
            {{ myIp }}
        </el-form-item>
        <el-form-item label="Please enter a website domain or IP address:">
            <el-input v-model="url" style="width: 300px" placeholder="Defaults to local machine address if not specified." clearable></el-input>
            <el-button style="margin-left: 10px" type="primary" @click="encode()">Query</el-button>
        </el-form-item>
    </el-form>
    <div class="btn-area">
      
    </div>
    <el-form :model="ipResult">
        <el-form-item label="IP:">
            <div>
                {{ ipResult.query || '-' }}
            </div>
        </el-form-item>
        <el-form-item label="Geolocation:">
            <div v-if="ipResult.country">
                {{ ipResult.city + ' ' + ipResult.regionName + ' ' + ipResult.country }}
            </div>
            <div v-else>-</div>
        </el-form-item>
        <el-form-item label="Org:">
            <div>
                {{ ipResult.org || '-' }}
            </div>
        </el-form-item>
        <el-form-item label="longitude and Latitude: ">
            <div v-if="ipResult.lon">
                {{ ipResult.lon + ', ' + ipResult.lat }}
            </div>
            <div v-else>-</div>
        </el-form-item>
    </el-form>
    <div class="introduce">
        <p>IP Address Lookup tool provides global IP geolocation information.</p>

        <p>The principle of IP Address Lookup is based on the mapping relationship between IP addresses and geographical locations. When you use an IP Address Lookup service to query the location of an IP address, it actually searches a database that contains the mapping between IP addresses and geographical locations.</p>

        <p>These IP Address Lookup services typically rely on two main sources of data to build their databases:</p>

        <p>IP Address Allocation Organizations: IP address allocation organizations are responsible for assigning IP address blocks to different Internet service providers and organizations. These organizations maintain databases that record the allocation of IP addresses and the associated geographical regions. IP Address Lookup services can obtain IP address allocation information from these organizations and associate IP addresses with their corresponding geographical locations.</p>

        <p>Data Collection and Compilation: In addition to IP address allocation organizations, there are other entities that build IP geolocation databases through continuous data collection and compilation. These entities may collect the mapping information between IP addresses and geographical locations through various means, such as crawling publicly available network information or collaborating with Internet service providers. They then compile this information into a database for providing IP Address Lookup services.</p>

        <p>When you query the location of an IP address, the IP Address Lookup service searches its database for the relevant geographical location information associated with that IP address and returns it to you. This can help you determine the country, region, city, or Internet service provider associated with the IP address.</p>

        <p>It's important to note that IP Address Lookup is not 100% accurate, as IP address allocations and usage can change, and there may be some delay in updating the databases. Therefore, the query results may have slight deviations or inaccuracies.</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
.introduce {
    // text-indent: 28px;
}
</style>
