<script setup>
import { ref, onMounted } from "vue";
const code = ref("");
import { setMeta } from '@/utils/util'
const result = ref("");

onMounted(() => {
  document.title = "URL Encoding & Decoding";
  setMeta("description", "URL Encoding & Decoding,URL encoding, URL decoding")
  setMeta("keywords", "URL encoding,URL decoding")
})

const encode = () => {
  result.value = encodeURIComponent(code.value);
};

const decode = () => {
  result.value = decodeURIComponent(code.value);
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};

const reset = () => {
  result.value = "";
  code.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">URL Encoding & Decoding</h1>
    <el-input
      type="textarea"
      v-model="code"
      :rows="4"
      placeholder="Please enter the content to perform URL encoding and decoding 🤔"
    ></el-input>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">UrlEncode 👇</el-button>
      <el-button type="primary" @click="decode()">UrlDecode 👇</el-button>
    </div>
    <el-input
      type="textarea"
      v-model="result"
      :rows="4"
      placeholder="The output result is here 😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="Copied!"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >Copy</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >Clear</el-button
    >
    <div class="introduce">
      <h2>What is URL Encoding & Decoding?🤔</h2>
      <p>
        URL encoding and decoding are techniques used to convert special
        characters and non-ASCII characters into a format that can be safely
        transmitted and processed within a Uniform Resource Locator (URL),
        commonly known as a web address.
      </p>
      <p>
        URL Encoding:<br />
        URL encoding, also known as percent-encoding, is the process of
        replacing special characters with a percent sign (&quot;%&quot;)
        followed by their hexadecimal representation. This encoding is necessary
        to ensure that URLs are properly formatted and can be transmitted
        correctly across various systems and protocols.
      </p>
      <p>The following characters are typically encoded in URLs:</p>
      <ul>
        <li>
          Reserved characters: such as space, slash (&quot;/&quot;), question
          mark (&quot;?&quot;), ampersand (&quot;&amp;&quot;), etc.
        </li>
        <li>
          Non-alphanumeric characters: such as symbols and punctuation marks.
        </li>
        <li>
          Non-ASCII characters: such as accented letters or characters from
          other languages.
        </li>
      </ul>
      <p>
        For example, if you have a URL with a space in it, the space character
        (&quot;%20&quot; in hexadecimal) will be encoded as &quot;%20&quot; to
        be properly interpreted by web browsers or other software.
      </p>
      <p>
        URL Decoding:<br />
        URL decoding is the reverse process of URL encoding. It involves
        converting encoded characters back to their original form. Decoding is
        necessary when receiving and processing a URL that has been encoded.
      </p>
      <p>
        URL decoding is performed by replacing the percent-encoded sequences
        with their corresponding characters. For example, &quot;%20&quot; is
        decoded into a space character.
      </p>
      <p>
        Many programming languages and web frameworks provide built-in functions
        or libraries to handle URL encoding and decoding. These functions make
        it easy to encode or decode URLs programmatically.
      </p>
      <p>
        URL encoding and decoding are essential in web development, especially
        when dealing with user input, handling query strings, or passing
        parameters in URLs. They ensure that special characters are properly
        handled and that URLs are transmitted and interpreted correctly across
        different systems.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
