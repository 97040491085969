<script setup>
import { onMounted, ref, watchEffect } from "vue";
import router from "@/router";
import { setMeta } from "@/utils/util";
import { searchKeyStore } from "@/stores/counter";
import FriendLink from "@/components/FriendLink.vue";

const list = [
  {
    title: "IP归属地查询",
    describe: "IP归属地查询工具，提供全球IP归属地查询。",
    onlineUrl: window.location.origin + "/zh/ip",
    router: "/zh/ip",
    icon: require('../../assets/img/search.png'),
  },
  {
    title: "Base64编解码",
    describe: "对字符进行Base64编解码，以及对图片进行Base64格式转换。",
    onlineUrl: window.location.origin + "/zh/base64",
    router: "/zh/base64",
    icon: require('../../assets/img/code.png'),
  },
  {
    title: "JSON格式化",
    describe: "对JSON内容进行格式化解析,按JSON层级展现。",
    onlineUrl: window.location.origin + "/zh/json",
    router: "/zh/json",
    icon: require('../../assets/img/keyboard.png'),
  },
  {
    title: "时间戳(Unix timestamp)转换工具",
    describe: "将时间戳(Unix timestamp)转换成北京时间",
    onlineUrl: window.location.origin + "/zh/timestamp",
    router: "/zh/timestamp",
    icon: require('../../assets/img/clock.png'),
  },
  {
    title: "UUID生成器",
    describe: "批量生成UUID",
    onlineUrl: window.location.origin + "/zh/uuid",
    router: "/zh/uuid",
    icon: require('../../assets/img/random.png'),
  },
  {
    title: "在线生成二维码",
    describe: "输入字符生成二维码，支持网址",
    onlineUrl: window.location.origin + "/zh/qrcode",
    router: "/zh/qrcode",
    icon: require('../../assets/img/qr.png'),
  },
  {
    title: "图片在线裁剪",
    describe: "在线裁剪您的图片",
    onlineUrl: window.location.origin + "/zh/cropper",
    router: "/zh/cropper",
    icon: require('../../assets/img/crop.png'),
  },
  {
    title: "URL编解码",
    describe: "进行URL编码或解码成URL编码格式",
    onlineUrl: window.location.origin + "/zh/urlencode",
    router: "/zh/urlencode",
    icon: require('../../assets/img/pc.png'),
  },
  {
    title: "随机密码生成器",
    describe: "根据规则生成随机密码",
    onlineUrl: window.location.origin + "/zh/password",
    router: "/zh/password",
    icon: require('../../assets/img/random.png'),
  },
  {
    title: "在线Markdown编辑器",
    describe: "轻量、强大、安全，本地存储数据，支持导出pdf/md/html",
    onlineUrl: window.location.origin + "/zh/markdown",
    router: "/zh/markdown",
    icon: require('../../assets/img/md.png'),
  },
  {
    title: "Excel转JSON",
    describe: "将Excel文件转换成格式化的JSON",
    onlineUrl: window.location.origin + "/zh/excel2json",
    router: "/zh/excel2json",
    icon: require('../../assets/img/keyboard.png'),
  },
  {
    title: "Excel在线编辑器",
    describe: "👊强大的在线Excel编辑器，无需多言",
    onlineUrl: window.location.origin + "/zh/excel",
    router: "/zh/excel",
    icon: require('../../assets/img/excel.png'),
  },
  {
    title: "多久下班？",
    describe: "下班倒计时",
    onlineUrl: window.location.origin + "/zh/countDown",
    router: "/zh/countDown",
    icon: require('../../assets/img/clock.png'),
  },
  {
    title: "英文名字生成器",
    describe: "批量生成随机英文名",
    onlineUrl: window.location.origin + "/zh/randomName",
    router: "/zh/randomName",
    icon: require('../../assets/img/random.png'),
  },
];

const searchList = ref(list);
const toTool = (url) => {
  router.push({
    path: url,
  });
};

const store = searchKeyStore();

const search = (searchKey) => {
  if (searchKey) {
    searchList.value = [];
    for (const item of list) {
      if (
        item.title.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
        item.describe.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      ) {
        searchList.value.push(item);
      }
    }
  } else {
    searchList.value = list;
  }
};

const delay = 100; // 每个字母的延迟时间

const typeWriter = (text, index) => {
  if (index < text.length) {
    if (document.getElementById("typing-text-zh")) {
      document.getElementById("typing-text-zh").innerHTML += text.charAt(index);
      index++;
      setTimeout(function () {
        typeWriter(text, index);
      }, delay);
    }
  }
};

onMounted(() => {
  document.title = "在线工具 -简洁、友好的开发者在线工具";
  setMeta(
    "description",
    "在线工具,开发者工具,Base64编码/解码,JSON格式化,二维码生成器,图片压缩,图片裁剪,URL编码/解码"
  );
  setMeta(
    "keywords",
    "在线工具,开发者工具,Base64编码/解码,JSON格式化,二维码生成器,图片压缩,图片裁剪,URL编码/解码"
  );

  typeWriter("工欲善其事，必先利其器。", 0);
  console.log(store.searchKey);
  search(store.searchKey || "");
});

watchEffect(() => {
  console.log(store.searchKey);
  search(store.searchKey || "");
});
</script>

<template>
  <h1 class="name colorful">
    <img class="logo-icon" src="@/assets/img/tools.png" alt="" />AnyTools
  </h1>
  <h2 class="big-title colorful" id="typing-text-zh"></h2>
  <div class="item-list">
    <div
      class="item"
      v-for="(item, index) in searchList"
      :key="index"
      @click="toTool(item.router)"
    >
      <div class="item-inner">
        <div class="item-title">
          <div>
            <img class="img-icon" :src="item.icon" alt="" />
            <!-- <div class="img-icon">{{ item.icon }}</div> -->
          </div>
          <div class="title">
            <span class="title-text" @click="toTool(item.router)">{{
              item.title
            }}</span>
            <div class="describe">
              {{ item.describe }}
            </div>
          </div>
        </div>
        <div class="item-content">
          <el-link type="primary" @click="toTool(item.router)">{{
            item.onlineUrl
          }}</el-link>
          <el-button type="text" @click="toTool(item.router)">进入</el-button>
        </div>
      </div>
    </div>
  </div>
  <FriendLink lang="zh"></FriendLink>
  <!-- <div class="more">
    More features are currently under development. Stay tuned! 💻
  </div> -->
</template>

<style lang="scss" scoped>
.name {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
.logo-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  top: 9px;
}
.big-title {
  // font-size: 40px;
  text-align: center;
  margin: 0px 0 40px 0;
  color: #999;
  // text-decoration: underline;
}
.item-list {
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  justify-content: space-evenly;
}
.item {
  border: 1px solid #e4e7ed;
  position: static;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-in-out;
}
.item:hover {
  cursor: pointer;
  box-shadow: var(--el-box-shadow-light);
}
.item-inner {
  padding: 10px 15px;
}

.item-title {
  display: flex;

  .title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 15px;
  }
  .title-text {
    font-weight: bold;
    // color: var(--el-color-primary);
    color: #333;
  }
  .title-text:hover {
    cursor: pointer;
  }
  .describe {
    font-size: 12px;
    color: #999;
    height: 60px;
    overflow: hidden;
  }
}

.item-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #999;
}

.img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.img-icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-shadow: 2px 2px 2px #000;
  box-shadow: #ccc 0px 0px 6px 1px
}
.more {
  text-align: center;
  margin-top: 30px;
  color: #999;
}
</style>
