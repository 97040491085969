<script setup>
import { ref, onMounted } from "vue";
const code = ref("");
import { setMeta } from "@/utils/util";
const result = ref("");

onMounted(() => {
  document.title = "URL编解码";
  setMeta("description", "URL编码、解码,URL编码, URL解码");
  setMeta("keywords", "URL编码,URL解码,URL编解码");
});

const encode = () => {
  result.value = encodeURIComponent(code.value);
};

const decode = () => {
  result.value = decodeURIComponent(code.value);
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};

const reset = () => {
  result.value = "";
  code.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">URL编解码</h1>
    <el-input
      type="textarea"
      v-model="code"
      :rows="4"
      placeholder="请提供要进行URL编码和解码的内容。🤔"
    ></el-input>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">Url编码(Encode)👇</el-button>
      <el-button type="primary" @click="decode()">Url解码(Decode) 👇</el-button>
    </div>
    <el-input
      type="textarea"
      v-model="result"
      :rows="4"
      placeholder="输出结果在这里😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="复制成功！"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >复制</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >清除</el-button
    >
    <div class="introduce">
      <h2>什么是URL编码和解码？🤔</h2>
      <p>
        URL编码和解码是一种将特殊字符和非ASCII字符转换为可以在统一资源定位符（URL）中安全传输和处理的格式的技术，URL通常称为网址。
      </p>
      <p>
        URL编码：<br />
        URL编码，也称为百分号编码（percent-encoding），是将特殊字符替换为百分号（“%”）后跟其十六进制表示形式的过程。这种编码是为了确保URL正确格式化，并能够在各种系统和协议之间正确传输。
      </p>
      <p>以下字符通常在URL中进行编码：</p>
      <ul>
        <li>
          保留字符：例如空格、斜杠（“/”）、问号（“?”）、和号（“&amp;”）等。
        </li>
        <li>非字母数字字符：例如符号和标点符号。</li>
        <li>非ASCII字符：例如带重音的字母或其他语言的字符。</li>
      </ul>
      <p>
        例如，如果URL中包含空格，则空格字符（十六进制中的“%20”）将被编码为“%20”，以便被Web浏览器或其他软件正确解释。
      </p>
      <p>
        URL解码：<br />
        URL解码是URL编码的反向过程。它涉及将编码字符转换回其原始形式。在接收和处理已编码的URL时，解码是必要的。
      </p>
      <p>
        URL解码通过将百分号编码序列替换为相应的字符来完成。例如，“%20”被解码为空格字符。
      </p>
      <p>
        许多编程语言和Web框架提供了内置函数或库来处理URL编码和解码。这些函数使得以编程方式对URL进行编码或解码变得简单。
      </p>
      <p>
        URL编码和解码在Web开发中非常重要，特别是在处理用户输入、处理查询字符串或在URL中传递参数时。它们确保特殊字符得到正确处理，并且URL能够在不同系统之间正确传输和解释。
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
