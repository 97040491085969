<script setup>
import { setMeta } from '@/utils/util'
import { onMounted, reactive, ref } from "vue";
const inputValue = ref("");
const result = ref("");

const type = ref("millsecond");
const now = ref("");

const inputValue1 = ref(new Date());
const result1 = ref("");
const defaultTime = new Date();

let interval = 0;

const timeZones = Intl.DateTimeFormat().resolvedOptions().timeZone;

onMounted(() => {
  document.title = "Timestamp Conversion";
  setMeta("description", "Unix timestamp conversion that can convert Unix time to datetime")
  setMeta("keywords", "Unix timestamp conversion,timestamp conversion tool")

  console.log(timeZones);
  now.value = inputValue.value = new Date().getTime(); //毫秒
  getNow();
});

const encode = () => {
  const timestamp = Number(
    type.value === "millsecond" ? inputValue.value : inputValue.value * 1000
  );
  const date = new Date(timestamp);
  const formattedDate = date
    .toLocaleString("zh", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: timeZones, //
    })
    .replace(/\//g, "-");
  console.log(formattedDate); // 输出：2021-07-31 16:29:05
  result.value = formattedDate;
};

const decode = () => {
  if (type.value === "millsecond") {
    result1.value = new Date(inputValue1.value).getTime();
  }
  if (type.value === "second") {
    let timestamp = new Date(inputValue1.value).getTime();
    let timestampInSeconds = Math.floor(timestamp / 1000); //转换成秒
    result1.value = timestampInSeconds;
  }
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};

const copy1 = () => {
  navigator.clipboard.writeText(result1.value);
};

const reset = () => {
  result.value = "";
};

const changeType = () => {
  if (type.value === "millsecond") {
    now.value = new Date().getTime();
  }
  if (type.value === "second") {
    let timestamp = new Date().getTime();
    let timestampInSeconds = Math.floor(timestamp / 1000); //转换成秒
    now.value = timestampInSeconds;
  }
  clearInterval(interval);
  getNow();
};

const getNow = () => {
  interval = setInterval(() => {
    if (type.value === "millsecond") {
      now.value = new Date().getTime();
    } else if (type.value === "second") {
      let timestamp = new Date().getTime();
      let timestampInSeconds = Math.floor(timestamp / 1000); //转换成秒
      now.value = timestampInSeconds;
    }

    console.log(interval);
  }, 1000);
};

const fuzhi = () => {
  inputValue.value = now.value;
};
</script>

<template>
  <div class="tool-body">
    <h1 class="text-red-300" style="margin-bottom: 20px">
      Timestamp Conversion
    </h1>

    <el-form label-width="90px" label-position="left">
      <el-form-item label="Unit">
        <el-radio-group v-model="type" @change="changeType">
          <el-radio label="millsecond">Milliseconds</el-radio>
          <el-radio label="second">Seconds</el-radio>
        </el-radio-group>
        <p style="margin-left: 30px">
          ( Please pay attention to the units 😀 )
        </p>
      </el-form-item>
      <el-form-item label="Now">
        {{ now }}
        <el-button class="ml-12" type="primary" @click="fuzhi"
          >Pass it down below 👇</el-button
        >
      </el-form-item>
      <el-form-item label="Timestamp">
        <el-input
          style="width: 214px"
          v-model="inputValue"
          placeholder="Please enter the timestamp 🤔"
          clearable
        ></el-input>
        <el-button class="ml-12" type="primary" @click="encode()"
          >Convert 👉</el-button
        >
        <el-input
          class="ml-12"
          style="width: 250px"
          v-model="result"
          :rows="8"
          placeholder="Output the time in your time zone 😀"
          clearable
        ></el-input>
        <el-popover
          placement="top"
          :auto-close="1000"
          trigger="click"
          popper-style="text-align: center;min-width:100px;width: 100px"
          content="Copied!"
        >
          <template #reference>
            <el-button type="primary" class="ml-12" @click="copy()"
              >Copy</el-button
            >
          </template>
        </el-popover>
      </el-form-item>
      <el-form-item label="Datetime">
        <el-date-picker
          style="width: 214px"
          v-model="inputValue1"
          type="datetime"
          placeholder="Select Datetime🤔"
          :default-time="defaultTime"
        />
        <el-button class="ml-12" type="primary" @click="decode()"
          >Convert 👉</el-button
        >
        <el-input
          class="ml-12"
          style="width: 250px"
          v-model="result1"
          :rows="8"
          placeholder="Output the timestamp 😀"
          clearable
        ></el-input>
        <el-popover
          placement="top"
          :auto-close="1000"
          trigger="click"
          popper-style="text-align: center;min-width:100px;width: 100px"
          content="Copied!"
        >
          <template #reference>
            <el-button type="primary" class="ml-12" @click="copy1()"
              >Copy</el-button
            >
          </template>
        </el-popover>
      </el-form-item>
    </el-form>
    <div class="introduce">
      <h2>What is Unix Timestamp?🤔</h2>
      <p>
        A Unix timestamp, also known as Unix time or POSIX time, is a system for
        representing time as the number of seconds that have elapsed since
        00:00:00 Coordinated Universal Time (UTC) on January 1, 1970 (excluding
        leap seconds). It is widely used in computer systems and programming
        languages for timestamping events, measuring time intervals, and
        performing calculations involving dates and times.
      </p>
      <p>
        Unix timestamps are based on the concept of Coordinated Universal Time
        (UTC), which is an internationally recognized time standard. UTC is used
        to ensure consistent timekeeping across different time zones and is not
        affected by daylight saving time changes.
      </p>
      <p>
        The Unix timestamp is a single integer value that represents the number
        of seconds elapsed since the specified starting point. It is typically
        stored as a 32-bit or 64-bit signed integer, depending on the
        programming language or system. The value increments by one for each
        passing second.
      </p>
      <p>A few examples of Unix timestamps:</p>
      <ul>
        <li>
          The Unix timestamp for the start of January 1, 1970, 00:00:00 UTC is
          0.
        </li>
        <li>
          The Unix timestamp for January 1, 2000, 12:00:00 UTC is 946,684,800
          (representing the number of seconds between the two dates).
        </li>
        <li>
          The Unix timestamp for the current date and time can be obtained by
          querying the system or using programming language functions.
        </li>
      </ul>
      <p>
        Unix timestamps are especially useful for storing and comparing dates
        and times in a standardized format that is independent of time zones.
        They are commonly used in databases, log files, and various applications
        where accurate time measurement is required.
      </p>
      <p>
        To work with Unix timestamps in programming languages, there are usually
        built-in functions or libraries available that allow conversion between
        Unix timestamps and human-readable dates, as well as performing
        calculations such as adding or subtracting time intervals.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.ml-12 {
  margin-left: 12px;
}
.upload {
  margin-left: 12px;
}
</style>
