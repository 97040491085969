import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView";
import HomeViewZH from "../views/zh/HomeView";
import Base64 from "../views/Base64";
import Base64ZH from "../views/zh/Base64";
import JSONFormatter from "../views/JSONFormatter";
import JSONFormatterZH from "../views/zh/JSONFormatter";
import Timestamp from "../views/Timestamp";
import TimestampZH from "../views/zh/Timestamp";
import Uuid from "../views/Uuid";
import UuidZH from "../views/zh/Uuid";
import QRcode from "../views/QrCode"
import QRcodeZH from "../views/zh/QrCode"
import Cropper from "../views/Cropper"
import CropperZH from "../views/zh/Cropper"
import UrlCode from "../views/UrlCode"
import UrlCodeZH from "../views/zh/UrlCode"
import Password from "../views/Password"
import PasswordZH from "../views/zh/Password"
import MarkdownZH from "../views/zh/Markdown"
import Markdown from "../views/Markdown"
import Excel2JSONZH from "../views/zh/Excel2JSON"
import Excel2JSON from "../views/Excel2JSON"
import ExcelZH from "../views/zh/Excel"
import Excel from "../views/Excel"
import CaseConversion from "../views/CaseConversion"
import Ip from "../views/Ip.vue"
import IpZH from "../views/zh/Ip.vue"
import Blog from "../views/Blog.vue"
import BlogZH from "../views/zh/Blog.vue"
import BlogDetailZH from "../views/zh/BlogDetail.vue"
import CountDown from "../views/zh/CountDown.vue"
import RandomName from "../views/RandomName.vue"
import RandomNameZH from "../views/zh/RandomName.vue"
import Upload from "../views/Upload.vue"



const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/zh",
      name: "homeZH",
      component: HomeViewZH,
    },
    {
      path: "/base64",
      name: "base64",
      component: Base64,
    },
    {
      path: "/zh/base64",
      name: "Base64ZH",
      component: Base64ZH,
    },
    {
      path: "/json",
      name: "json",
      component: JSONFormatter,
    },
    {
      path: "/zh/json",
      name: "jsonZH",
      component: JSONFormatterZH,
    },
    {
      path: "/timestamp",
      name: "timestamp",
      component: Timestamp,
    },
    {
      path: "/zh/timestamp",
      name: "TimestampZH",
      component: TimestampZH,
    },
    {
      path: "/uuid",
      name: "uuid",
      component: Uuid,
    },
    {
      path: "/zh/uuid",
      name: "UuidZH",
      component: UuidZH,
    },
    {
      path: "/qrcode",
      name: "qrcode",
      component: QRcode,
    },
    {
      path: "/zh/qrcode",
      name: "qrcodeZH",
      component: QRcodeZH,
    },
    {
      path: "/cropper",
      name: "cropper",
      component: Cropper,
    },
    {
      path: "/zh/cropper",
      name: "cropperZH",
      component: CropperZH,
    },
    {
      path: "/urlencode",
      name: "urlencode",
      component: UrlCode,
    },
    {
      path: "/zh/urlencode",
      name: "UrlCodeZH",
      component: UrlCodeZH,
    },
    {
      path: "/password",
      name: "password",
      component: Password,
    },
    {
      path: "/zh/password",
      name: "PasswordZH",
      component: PasswordZH,
    },
    {
      path: "/zh/markdown",
      name: "MarkdownZH",
      component: MarkdownZH,
    },
    {
      path: "/markdown",
      name: "Markdown",
      component: Markdown,
    },
    {
      path: "/zh/excel2json",
      name: "Excel2JSONZH",
      component: Excel2JSONZH,
    },
    {
      path: "/excel2json",
      name: "Excel2JSON",
      component: Excel2JSON,
    },
    {
      path: "/zh/Excel",
      name: "ExcelZH",
      component: ExcelZH,
    },
    {
      path: "/Excel",
      name: "Excel",
      component: Excel,
    },
    {
      path: "/CaseConversion",
      name: "CaseConversion",
      component: CaseConversion,
    },
    {
      path: "/ip",
      name: "Ip",
      component: Ip,
    },
    {
      path: "/zh/ip",
      name: "IpZH",
      component: IpZH,
    },
    {
      path: "/blog",
      name: "Blog",
      component: Blog,
    },
    {
      path: "/zh/blog",
      name: "BlogZH",
      component: BlogZH,
    },
    {
      path: "/zh/blogDetail",
      name: "BlogDetailZH",
      component: BlogDetailZH,
    },
    {
      path: "/zh/countDown",
      name: "CountDown",
      component: CountDown,
    },
    {
      path: "/randomName",
      name: "RandomName",
      component: RandomName,
    },
    {
      path: "/zh/randomName",
      name: "RandomNameZH",
      component: RandomNameZH,
    },
    {
      path: "/upload",
      name: "Upload",
      component: Upload,
    },
  ],
});

export default router;
