<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import { UploadFilled } from "@element-plus/icons-vue";
onMounted(() => {
  document.title = "图床托管工具";
  setMeta("description", "图床托管工具");
  setMeta("keywords", "图床托管工具");
});
const num = ref(1);

const uploadSuccess = (response, uploadFile, uploadFiles) => {
    console.log(response);
    
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">图床托管工具</h1>
    <el-upload
      class="upload-demo"
      drag
      action="https://pic.anytools.me/upload"
      :on-success="uploadSuccess"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        Drop file here or <em>click to upload</em>
      </div>
      <template #tip>
        <div class="el-upload__tip">
          jpg/png files with a size less than 500kb
        </div>
      </template>
    </el-upload>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
