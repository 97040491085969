<script setup>
import { ref, onMounted } from "vue";
import JsonViewer from "vue-json-viewer";
const code = ref("");
const jsonData = ref("");
import { setMeta } from '@/utils/util'
const errorShow = ref(false);

onMounted(() => {
  document.title = "JSON Formatting";
  setMeta("description", "JSON editor,JSON formatting,syntax highlighting,syntax checking")
  setMeta("keywords", "JSON format,JSON formatting")

})

const copy = () => {
  navigator.clipboard.writeText(jsonData.value);
};

const reset = () => {
  jsonData.value = "";
  code.value = "";
  errorShow.value = false;
};

const encode = () => {
  try {
    errorShow.value = false;
    jsonData.value = JSON.parse(code.value);
  } catch (error) {
    jsonData.value = code.value;
    errorShow.value = true;
  }
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">JSON Formatting</h1>
    <el-input
      type="textarea"
      v-model="code"
      :rows="8"
      placeholder="Please enter the JSON string you want to format 🤔"
    ></el-input>
    <el-alert
      style="margin-top: 10px"
      type="error"
      effect="dark"
      v-show="errorShow"
      title="JSON format error!"
      show-icon
      :closable="false"
    ></el-alert>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">Format 👇</el-button>
    </div>
    <el-alert
      title="Tip: Press 'Alt' key and click the left mouse button to expand all nodes under the current node."
      type="info"
      show-icon
      :closable="false"
    />
    <JsonViewer
      style="margin-top: 10px"
      :value="jsonData"
      boxed
      :expanded="true"
      :copyable="{ copyText: 'Copy', copiedText: 'Copied!' }"
    >
    </JsonViewer>

    <el-button style="margin-top: 10px" type="" @click="reset()"
      >Clear</el-button
    >
    <div class="introduce">
      <h2>What is JSON?🤔</h2>
      <p>
        JSON (JavaScript Object Notation) is a lightweight data interchange
        format that is commonly used for transmitting and storing data. It is a
        text-based format that is easy for humans to read and write, and it is
        also easy for machines to parse and generate.
      </p>
      <p>
        JSON is often used as a way to represent structured data in a key-value
        format. It is based on a subset of the JavaScript programming language
        syntax, but it is language-independent and can be used with various
        programming languages.
      </p>
      <p>
        In JSON, data is organized into objects and arrays. An object is an
        unordered collection of key-value pairs, where each key is a string and
        each value can be a string, number, boolean, null, object, or array. An
        array is an ordered collection of values, which can also be strings,
        numbers, booleans, null, objects, or arrays.
      </p>
      <p>An example of a simple JSON object:</p>
      <JsonViewer
        style="margin-top: 10px"
        :value='{ "name": "John Doe", "age": 30, "city": "New York" }'
        boxed
        :expanded="true"
        :copyable="{ copyText: 'Copy', copiedText: 'Copied!' }"
      >
      </JsonViewer>
      <p>
        In this example, we have an object with three key-value pairs. The keys
        are &quot;name&quot;, &quot;age&quot;, and &quot;city&quot;, and the
        corresponding values are &quot;John Doe&quot;, 30, and &quot;New
        York&quot; respectively.
      </p>
      <p>
        JSON is widely used in web development and API integrations as a means
        of exchanging data between different systems. It is supported by most
        programming languages through built-in functions or libraries, allowing
        developers to easily parse JSON data and convert it into native data
        structures in their respective languages.
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
::v-deep .jv-button {
  color: var(--el-color-primary) !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
::v-deep pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}
</style>
