<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
onMounted(() => {
  document.title = "UUID生成器";
  setMeta("description", "UUID生成器,批量生成UUID");
  setMeta("keywords", "UUID生成器,批量生成UUID,生成UUID");
});
const num = ref(1);
const result = ref("");

const isHeng = ref(true);

const encode = () => {
  let arr = [];
  for (let i = 0; i < num.value; i++) {
    arr.push(generateUUID());
  }
  result.value = arr.join("\n");
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};

const generateUUID = () => {
  let d = new Date().getTime();
  let str = isHeng.value
    ? "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    : "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx";
  let uuid = str.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const reset = () => {
  result.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">UUID生成器</h1>
    <el-form>
      <el-form-item label="生成数量">
        <el-input-number v-model="num" :min="1" :max="500" />
      </el-form-item>
      <el-form-item label="是否包含横杠">
        <el-checkbox v-model="isHeng"></el-checkbox>
      </el-form-item>
    </el-form>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">生成👇</el-button>
    </div>
    <el-input
      type="textarea"
      v-model="result"
      :rows="8"
      placeholder="生成的结果在这里😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="复制成功！"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >复制</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >清除</el-button
    >
    <div class="introduce">
      <h2>什么是UUID？🤔</h2>
      <p>
        UUID（通用唯一标识符）是一种标准化的标识符，旨在在所有设备和时间上都是唯一的。它是一个128位的值，通常表示为由连字符分隔的字母数字字符串，以增强可读性。
      </p>
      <p>
        UUID通常在各种系统和应用程序中用于唯一标识资源、对象或实体。与其他类型的标识符（如顺序编号或数据库自增值）不同，UUID提供了更高级别的唯一性，并且可以独立生成，无需集中协调。
      </p>
      <p>
        UUID的结构遵循特定的格式。它由五组字符组成，由连字符分隔，遵循以下模式：
      </p>
      <p>xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx</p>
      <ul>
        <li>
          第一组表示一个32位的十六进制值，通常源自当前系统时间或随机数生成器。
        </li>
        <li>
          第二组表示版本号，指定正在使用的UUID变体。例如，常用的是版本4（随机生成）和版本5（基于命名空间标识符和名称的哈希）。
        </li>
        <li>
          第三组表示变体号，指示UUID的布局和解释方式。最常见的变体是“变体2”，由RFC
          4122定义。
        </li>
        <li>
          第四组是一个保留值，称为“时钟序列”，它在快速生成UUID的场景中有助于确保唯一性。
        </li>
        <li>第五组表示一个48位的值，通常源自生成设备的网络MAC地址。</li>
      </ul>
      <p>UUID的一个示例：</p>
      <p>550e8400-e29b-41d4-a716-446655440000</p>
      <p>
        UUID广泛应用于数据库系统、分布式系统、消息传输协议和Web应用程序等各个领域。它们提供了一种可靠的方式来唯一标识资源，无需集中协调或依赖顺序编号系统。
      </p>
      <p>
        UUID具有多个优点，如唯一性、低碰撞概率和能够独立生成。然而，与其他标识符格式相比，它们相对较长且不太易读。尽管如此，它们的优势使它们成为需要全局唯一标识符的场景中的流行选择。
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
