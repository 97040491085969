<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
onMounted(() => {
  document.title = "UUID Generator";
  setMeta("description", "UUID generator, batch UUID generation");
  setMeta("keywords", "UUID generator,batch UUID generation,UUID generate");
});
const letter = ref("");

const convertString = (str, option) => {
  let words = str.split(" ");
  let convertedWords = [];

  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    let convertedWord = "";

    if (option === 1) {
      // 全部转大写
      convertedWord = word.toUpperCase();
    } else if (option === 2) {
      // 全部转小写
      convertedWord = word.toLowerCase();
    } else if (option === 3) {
      // 每个英文单词的开头大写，其余部分小写
      convertedWord =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    convertedWords.push(convertedWord);
  }

  letter.value = convertedWords.join(" ");
};

const copy = () => {
  navigator.clipboard.writeText(letter.value);
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">English Case Conversion</h1>
    <el-input
      v-model="letter"
      :rows="8"
      type="textarea"
      placeholder=""
      size="normal"
      clearable
    ></el-input>
    <div class="btn-area">
      <el-button type="default" size="default" @click="convertString(letter, 1)"
        >转大写</el-button
      >
      <el-button type="default" size="default" @click="convertString(letter, 2)"
        >转小写</el-button
      >
      <el-button type="default" size="default" @click="convertString(letter, 3)"
        >首字母大写</el-button
      >
      <el-popover
        placement="top"
        :auto-close="1000"
        trigger="click"
        popper-style="text-align: center;min-width:100px;width: 100px"
        content="Copied!"
      >
        <template #reference>
          <el-button type="primary" @click="copy()"
            >Copy</el-button
          >
        </template>
      </el-popover>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
