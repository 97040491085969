import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const searchKeyStore = defineStore('searchKeyStore', () => {
  const searchKey = ref('')
  
  function changeSearchKey(value) {
    searchKey.value = value
  }

  return { searchKey, changeSearchKey }
})

export const langStore = defineStore('langStore', () => {
  const lang = ref('English')
  
  function changeLang(value) {
    lang.value = value
    sessionStorage.setItem('lang', value)
  }

  return { lang, changeLang }
})
