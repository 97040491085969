<template>
  <div id="vditor" />
  <el-drawer
    v-model="drawer"
    title="我的文章列表（只保留在本地浏览器存储哦）"
    direction="rtl"
  >
    <el-table :data="articleList" style="width: 100%">
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="time" label="上次保存时间" width="200" />
      <el-table-column label="操作" fixed="right" width="100">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="editArtcle(scope)"
            >编辑</el-button
          >
          <el-button
            link
            type="danger"
            size="small"
            @click="deleteArtcle(scope)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Vditor from "vditor";
import { setMeta, formatDate } from "@/utils/util";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import "vditor/dist/index.css";

const vditor = ref(null);

const articleList = ref([]);
const article = ref({});

const drawer = ref(false);

const defaultContent =
  "## 欢迎使用 `AnyTools` 在线 Markdown 编辑器" +
  "\n[AnyTools Markdown](https://AnyTools.me/zh/markdown)，这是一个基于`Vue`和`Vditor`构建的在线Markdown编辑器，旨在提供轻量、强大的功能。它具有多种特性，包括内置粘贴HTML自动转换为Markdown、支持流程图、甘特图、时序图和任务列表，以及导出带有样式的图片、PDF和适用于微信公众号的特定HTML格式等等。" +
  "\n\n注意：编辑内容只会在本地进行保存（localstorage），不会上传任何数据至服务器，**不会收集用户个人隐私，可放心使用**；" +
  "\n## 使用方式" +
  "\n在左侧编辑框清空这份默认内容，就可以开始使用，右侧是实时预览效果。点击工具栏的三个点（更多），可以导出`.md`,`.pdf`,`html`格式的文件" +
  "\n\n**右上角两个按钮，一个是保存功能，即保存到浏览器本地，关闭浏览器也不会消失，另一个是查看已保存的文章列表**" +
  "\n## 功能示例" +
  "\n### 1. 搞一个 `Todo List`" +
  "\n- [x] 🏃‍早起跑步" +
  "\n- [x] 🍚吃早饭" +
  "\n- [x] 📕看书2小时" +
  "\n- [x] 🍜吃午饭" +
  "\n- [x] 🎮玩王国之泪，通关3个神庙和收集10个呀哈哈" +
  "\n- [x] 😅~~测你二维码~~" +
  "\n- [x] ............." +
  "\n### 2. 写一个数学物理公式[^LaTeX]" +
  "\n$$" +
  "\nf'(x)=lim[h→0](f(x+h)-f(x))/h" +
  "\n$$" +
  "\n---";

const initArticleList = () => {
  articleList.value = JSON.parse(localStorage.getItem("articleList")) || [];
  console.log(articleList.value);
};

const initEditor = (article) => {
  vditor.value = new Vditor("vditor", {
    after: () => {
      const cache = localStorage.getItem("vditorvditor");
      console.log(cache === "\n");
      if (cache && cache !== "\n") {
        vditor.value.setValue(article? article.content: cache);
      } else {
        vditor.value.setValue(defaultContent);
      }
    },
    mode: "sv",
    width: "100%",
    minHeight: "500px",
    preview: {
      theme: {
        current: "light",
      },
    },
    toolbar: [
      "emoji",
      "headings",
      "bold",
      "italic",
      "strike",
      "|",
      "line",
      "quote",
      "list",
      "ordered-list",
      "check",
      "outdent",
      "indent",
      "code",
      "inline-code",
      "insert-after",
      "insert-before",
      "undo",
      "redo",
      "link",
      "table",
      "record",
      "edit-mode",
      "both",
      "preview",
      "fullscreen",
      "outline",
      "code-theme",
      "content-theme",
      "export",
      "devtools",
      "help",
      "br",
      {
        name: "myArticle",
        tipPosition: "n",
        tip: "我的文章列表",
        className: "custom-btn",
        icon: '<svg t="1697120275224" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="200" height="200"><path d="M909.024 142a94.832 94.832 0 0 0-43.584-61.792 105.408 105.408 0 0 0-76.8-14.048 100.176 100.176 0 0 0-65.008 41.424l-101.216 144.64-343.552 492.208a31.456 31.456 0 0 0-5.168 11.2v1.408l-33.984 166.4a26.08 26.08 0 0 0 4.304 23.232 31.472 31.472 0 0 0 19.952 12.64 21.472 21.472 0 0 0 6.656 0.704 29.824 29.824 0 0 0 16.992-4.912l152.192-94.096a29.136 29.136 0 0 0 9.6-9.136l340.592-487.296 104.912-149.552a93.68 93.68 0 0 0 14.112-73.024zM737.616 332.272L400.72 814.656l-86.4 53.36 19.2-94.784 340.592-488 101.216-144.64a40 40 0 0 1 25.12-16.144 41.712 41.712 0 0 1 30.288 5.616 37.888 37.888 0 0 1 16.992 23.872 36.464 36.464 0 0 1-5.92 28.8z" fill="#088395" p-id="5072"></path><path d="M488.736 250.976h-387.2a36.944 36.944 0 0 1 0-73.888h387.2a36.944 36.944 0 1 1 0 73.888z" fill="#088395" p-id="5073"></path><path d="M372.8 545.808H100.944a36.944 36.944 0 0 1 0-73.888H372.8a36.944 36.944 0 1 1 0 73.888z" fill="#088395" p-id="5074"></path><path d="M229.536 854.352H101.712a36.944 36.944 0 0 1 0-73.888h127.824a36.944 36.944 0 0 1 0 73.888z" fill="#088395" p-id="5075"></path><path d="M923.056 854.352H703.632a36.944 36.944 0 1 1 0-73.888h219.424A36.576 36.576 0 0 1 960 817.408a37.04 37.04 0 0 1-36.944 36.944z" fill="#088395" p-id="5076"></path><path d="M922.976 529.28h-116.8a36.944 36.944 0 1 1 0-73.888h116.8a36.576 36.576 0 0 1 36.944 36.944 37.056 37.056 0 0 1-36.944 36.944z" fill="#088395" p-id="5077"></path></svg>',
        click() {
          drawer.value = true;
        },
      },
      {
        name: "save",
        tipPosition: "n",
        tip: "另存为",
        className: "custom-btn",
        icon: '<svg t="1697118854664" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4078" width="200" height="200"><path d="M548.8 177.6H624v148.8h-75.2v-148.8zM102.4 102.4h75.2V400c0 44.8 30.4 75.2 75.2 75.2h446.4c44.8 0 75.2-30.4 75.2-75.2V102.4h75.2v372.8h75.2V102.4c0-44.8-30.4-75.2-75.2-75.2H102.4c-44.8 0-75.2 30.4-75.2 75.2v819.2c0 44.8 30.4 75.2 75.2 75.2H624v-75.2H102.4V102.4z m148.8 0h446.4V400H251.2V102.4zM944 683.2l-156.8-156.8-51.2 52.8 118.4 118.4h-528v75.2h528L736 891.2l52.8 52.8 156.8-156.8 52.8-52.8-54.4-51.2z" fill="#088395" p-id="4079"></path></svg>',
        click() {
          console.log(vditor.value.getValue());
          let article = vditor.value.getValue();

          ElMessageBox.prompt("输入文章标题", "另存为", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputValue: truncateString(article),
          })
            .then(({ value }) => {
              console.log(value);
              articleList.value.push({
                id: generateUUID(),
                title: value,
                content: article,
                time: formatDate(new Date()),
              });
              localStorage.setItem(
                "articleList",
                JSON.stringify(articleList.value)
              );
              ElMessage({
                type: "success",
                message: `保存成功！只能在本浏览器可以查看已保存文章`,
              });
            })
            .catch(() => {});
        },
      },
    ],
  });
};

const generateUUID = () => {
  let d = new Date().getTime();
  let str = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  let uuid = str.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const truncateString = (str) => {
  if (str.length <= 10) {
    return str;
  } else {
    return str.substring(0, 10);
  }
};

const editArtcle = (scope) => {
  article.value = scope.row;
  initEditor(article.value)
  drawer.value = false
};
const deleteArtcle = (scope) => {
  ElMessageBox.confirm("删了就永远消失了，确定吗？", "🤔", {
    confirmButtonText: "随它去",
    cancelButtonText: "再想想",
    type: "warning",
  })
    .then(() => {
      articleList.value.splice(scope.$index, 1);
      localStorage.setItem("articleList", JSON.stringify(articleList.value));
    })
    .catch(() => {});
};

onMounted(() => {
  document.title = "在线Markdown编辑器";
  setMeta(
    "description",
    "AnyTools Markdown 编辑器；轻量、强大：粘贴 HTML 自动转换为 Markdown，支持 PPT 预览，以及绘制流程图、甘特图、时序图、任务列表、五线谱，可导出携带样式的图片、PDF、微信公众号特制的 HTML 等。"
  );
  setMeta(
    "keywords",
    "Markdown, Online, Emoji, HTML to Markdown, Markdown to PDF, Markdown to 图片, 编辑器, 在线, 微信公众号, 流程图, 甘特图, 时序图, 任务列表, 实时预览, 五线谱, PPT 预览, 支持移动端, 滚动同步定位, 字符计数"
  );
  initArticleList();
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
  })
  setTimeout(() => {
    loading.close()
  }, 1500)
  setTimeout(() => {
    initEditor();
  }, 1000);
});
</script>

<style lang="scss">
// .body {
//   padding: 0 !important;
//   padding-top: 100px !important;
// }
#vditor {
  margin-bottom: 60px;
  a {
    color: var(--el-color-primary);
  }
  strong {
    font-weight: bold;
  }
}
.custom-btn {
  float: right;
}
</style>
