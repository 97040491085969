<script setup>
import { Base64 } from "js-base64";
import { ref, onMounted } from "vue";
import { genFileId } from "element-plus";
import { setMeta } from "@/utils/util";

onMounted(() => {
  document.title = "Base64编解码";
  setMeta("description", "Base64编码,Base64解码,图片转Base64");
  setMeta("keywords", "Base64编码,Base64解码,图片转Base64");
});

const code = ref("");
const encryptCode = ref("");
const upload = ref();

const encode = () => {
  encryptCode.value = Base64.encode(code.value);
};

const decode = () => {
  encryptCode.value = Base64.decode(code.value);
};

const handleExceed = (files) => {
  upload.value.clearFiles();
  const file = files[0];
  file.uid = genFileId();
  upload.value.handleStart(file);
};

const handleChange = (file) => {
  console.log(file);
  const reader = new FileReader();
  reader.onload = () => {
    const img = new Image();
    img.src = reader.result;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const base64data = canvas.toDataURL("image/jpeg");
      encryptCode.value = base64data;
    };
  };
  reader.readAsDataURL(file.raw);
};

const copy = () => {
  navigator.clipboard.writeText(encryptCode.value);
};

const reset = () => {
  encryptCode.value = "";
  code.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">Base64编解码</h1>
    <el-input
      type="textarea"
      v-model="code"
      :rows="8"
      placeholder="请输入需要进行Base64编解码的文本🤔"
    ></el-input>
    <div class="btn-area">
      <el-button type="primary" @click="encode()"
        >Base64编码(Encode)👇</el-button
      >
      <el-button type="primary" @click="decode()"
        >Base64解码(Decode)👇</el-button
      >
      <el-upload
        ref="upload"
        class="upload"
        :auto-upload="false"
        :limit="1"
        :on-exceed="handleExceed"
        :on-change="handleChange"
        accept="image/*"
      >
        <el-button type="primary" @click="decode()"
          >上传图片转Base64📷</el-button
        >
      </el-upload>
    </div>
    <el-input
      type="textarea"
      v-model="encryptCode"
      :rows="8"
      placeholder="输出结果在这里😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="复制成功!"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >复制结果</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >清空</el-button
    >
    <div class="introduce">
      <h2>什么是Base64？🤔</h2>
      <p>
        <strong
          >Base64是一种常用的编码方案，将二进制数据转换为可打印的ASCII字符。它是一种用于编码和解码数据的算法，广泛应用于网络传输和数据存储。</strong
        >
      </p>
      <p>
        <strong
          >Base64编码的原理是将数据分成三个字节的组，然后将每个组分割成四个6位的片段。每个片段都转换为可打印的ASCII字符。如果数据的字节数不是三的倍数，会添加填充以满足编码的要求。</strong
        >
      </p>
      <p><strong>Base64编码具有以下特点：</strong></p>
      <ol>
        <li>
          <strong
            >可打印性：编码后的数据仅由ASCII字符组成，在基于文本的环境中传输和存储时安全可靠。</strong
          >
        </li>
        <li>
          <strong
            >数据完整性：编码后的数据可以准确解码，还原为原始的二进制数据。</strong
          >
        </li>
        <li><strong>简单性：Base64编码算法简单高效，易于实现。</strong></li>
      </ol>
      <p><strong>Base64编码在各个领域都有广泛应用，包括：</strong></p>
      <ol>
        <li>
          <strong
            >数据传输：在网络通信中，Base64常用于将二进制数据编码为文本格式，以在类似HTTP或电子邮件的协议中进行传输。</strong
          >
        </li>
        <li>
          <strong
            >图像处理：在Web开发中，Base64编码使得将图像转换为文本格式成为可能，在HTML或CSS中嵌入图像数据更加便捷。</strong
          >
        </li>
        <li>
          <strong
            >数据存储：Base64编码可用于将二进制数据存储在文本文件或数据库中，同时保持数据的完整性和可读性。</strong
          >
        </li>
      </ol>
      <p>
        <strong
          >Base64编码仅是一种将二进制数据转换为文本格式的方法，并不提供数据加密或安全保护。在需要数据安全性时，应同时使用加密算法和Base64编码。</strong
        >
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
