<script setup>
import { Base64 } from "js-base64";
import { ref, onMounted } from "vue";
import { genFileId } from "element-plus";
import { setMeta } from '@/utils/util'

onMounted(() => {
  document.title = "Base64 encoding & decoding";
  setMeta("description", "Base64 encoding/decoding,Image Base64 encoding")
  setMeta("keywords", "Base64 encoding/decoding,Image Base64 encoding,Base64 encoding,Base64 decoding")
});

const code = ref("");
const encryptCode = ref("");
const upload = ref();

const encode = () => {
  encryptCode.value = Base64.encode(code.value);
};

const decode = () => {
  encryptCode.value = Base64.decode(code.value);
};

const handleExceed = (files) => {
  upload.value.clearFiles();
  const file = files[0];
  file.uid = genFileId();
  upload.value.handleStart(file);
};

const handleChange = (file) => {
  console.log(file);
  const reader = new FileReader();
  reader.onload = () => {
    const img = new Image();
    img.src = reader.result;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const base64data = canvas.toDataURL("image/jpeg");
      encryptCode.value = base64data;
    };
  };
  reader.readAsDataURL(file.raw);
};

const copy = () => {
  navigator.clipboard.writeText(encryptCode.value);
};

const reset = () => {
  encryptCode.value = "";
  code.value = "";
};
</script>

<template>
  <div class="tool-body">
    <h1 style="margin-bottom: 20px">Base64 encoding & decoding</h1>
    <el-input
      type="textarea"
      v-model="code"
      :rows="8"
      placeholder="Please enter the text you want to encode or decode in Base64🤔"
    ></el-input>
    <div class="btn-area">
      <el-button type="primary" @click="encode()">Base64 Encode 👇</el-button>
      <el-button type="primary" @click="decode()">Base64 Decode 👇</el-button>
      <el-upload
        ref="upload"
        class="upload"
        :auto-upload="false"
        :limit="1"
        :on-exceed="handleExceed"
        :on-change="handleChange"
        accept="image/*"
      >
        <el-button type="primary" @click="decode()"
          >Upload an image to convert it to Base64 📷</el-button
        >
      </el-upload>
    </div>
    <el-input
      type="textarea"
      v-model="encryptCode"
      :rows="8"
      placeholder="The output result is here 😀"
    ></el-input>
    <el-popover
      placement="top"
      :auto-close="1000"
      trigger="click"
      popper-style="text-align: center;min-width:100px;width: 100px"
      content="Copied!"
    >
      <template #reference>
        <el-button style="margin-top: 10px" type="primary" @click="copy()"
          >Copy</el-button
        >
      </template>
    </el-popover>
    <el-button style="margin-top: 10px" type="" @click="reset()"
      >Clear</el-button
    >
    <div class="introduce">
      <h2>What is Base64?🤔</h2>
      <p>
        <strong
          >Base64 is a commonly used encoding scheme that converts binary data
          into printable ASCII characters. It is an algorithm used for encoding
          and decoding data, widely employed in network transmission and data
          storage.</strong
        >
      </p>
      <p>
        <strong
          >The principle behind Base64 encoding involves dividing the data into
          groups of three bytes, which are then split into four 6-bit segments.
          Each segment is converted into a printable ASCII character. If the
          data is not a multiple of three bytes, padding is added to meet the
          encoding requirements.</strong
        >
      </p>
      <p>
        <strong
          >Base64 encoding possesses the following characteristics:</strong
        >
      </p>
      <ol>
        <li>
          <strong
            >Printability: The encoded data consists only of ASCII characters,
            making it safe for transmission and storage in text-based
            environments.</strong
          >
        </li>
        <li>
          <strong
            >Data integrity: The encoded data can be accurately decoded to
            retrieve the original binary data.</strong
          >
        </li>
        <li>
          <strong
            >Simplicity: The Base64 encoding algorithm is simple and efficient,
            making it easy to implement.</strong
          >
        </li>
      </ol>
      <p>
        <strong
          >Base64 encoding finds widespread applications in various fields,
          including:</strong
        >
      </p>
      <ol>
        <li>
          <strong
            >Data transmission: In network communication, Base64 is commonly
            used to encode binary data into text format for transmission in
            protocols like HTTP or email.</strong
          >
        </li>
        <li>
          <strong
            >Image processing: In web development, Base64 encoding enables the
            conversion of images into text format, facilitating embedding of
            image data within HTML or CSS.</strong
          >
        </li>
        <li>
          <strong
            >Data storage: Base64 encoding can be used to store binary data in
            text files or databases while preserving data integrity and
            readability.</strong
          >
        </li>
      </ol>
      <p>
        <strong
          >It is important to note that Base64 encoding is solely a method for
          converting binary data into a text format and does not offer data
          encryption or security protection. When data security is required,
          encryption algorithms should be used in addition to Base64
          encoding.</strong
        >
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.upload {
  margin-left: 12px;
}
</style>
