<script setup>
import { setMeta } from "@/utils/util";
import { onMounted, reactive, ref } from "vue";
const inputValue = ref("");
const result = ref("");

const type = ref("millsecond");
const now = ref("");

const inputValue1 = ref(new Date());
const result1 = ref("");
const defaultTime = new Date();

let interval = 0;

const timeZones = Intl.DateTimeFormat().resolvedOptions().timeZone;

onMounted(() => {
  document.title = "时间戳(Unix timestamp)转换工具";
  setMeta("description", "将时间戳(Unix timestamp)转换成北京时间");
  setMeta("keywords", "时间戳,unix,时间戳转换");

  console.log(timeZones);
  now.value = inputValue.value = new Date().getTime(); //毫秒
  getNow();
});

const encode = () => {
  const timestamp = Number(
    type.value === "millsecond" ? inputValue.value : inputValue.value * 1000
  );
  const date = new Date(timestamp);
  const formattedDate = date
    .toLocaleString("zh", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: timeZones, //
    })
    .replace(/\//g, "-");
  console.log(formattedDate); // 输出：2021-07-31 16:29:05
  result.value = formattedDate;
};

const decode = () => {
  if (type.value === "millsecond") {
    result1.value = new Date(inputValue1.value).getTime();
  }
  if (type.value === "second") {
    let timestamp = new Date(inputValue1.value).getTime();
    let timestampInSeconds = Math.floor(timestamp / 1000); //转换成秒
    result1.value = timestampInSeconds;
  }
};

const copy = () => {
  navigator.clipboard.writeText(result.value);
};

const copy1 = () => {
  navigator.clipboard.writeText(result1.value);
};

const reset = () => {
  result.value = "";
};

const changeType = () => {
  if (type.value === "millsecond") {
    now.value = new Date().getTime();
  }
  if (type.value === "second") {
    let timestamp = new Date().getTime();
    let timestampInSeconds = Math.floor(timestamp / 1000); //转换成秒
    now.value = timestampInSeconds;
  }
  clearInterval(interval);
  getNow();
};

const getNow = () => {
  interval = setInterval(() => {
    if (type.value === "millsecond") {
      now.value = new Date().getTime();
    } else if (type.value === "second") {
      let timestamp = new Date().getTime();
      let timestampInSeconds = Math.floor(timestamp / 1000); //转换成秒
      now.value = timestampInSeconds;
    }

    console.log(interval);
  }, 1000);
};

const fuzhi = () => {
  inputValue.value = now.value;
};
</script>

<template>
  <div class="tool-body">
    <h1 class="text-red-300" style="margin-bottom: 20px">
      时间戳(Unix timestamp)转换工具
    </h1>

    <el-form label-width="90px" label-position="left">
      <el-form-item label="单位">
        <el-radio-group v-model="type" @change="changeType">
          <el-radio label="millsecond">毫秒</el-radio>
          <el-radio label="second">秒</el-radio>
        </el-radio-group>
        <p style="margin-left: 30px">( 注意单位哦😀 )</p>
      </el-form-item>
      <el-form-item label="Now">
        {{ now }}
        <el-button class="ml-12" type="primary" @click="fuzhi"
          >传给下面👇</el-button
        >
      </el-form-item>
      <el-form-item label="Timestamp">
        <el-input
          style="width: 214px"
          v-model="inputValue"
          placeholder="请输入时间戳🤔"
          clearable
        ></el-input>
        <el-button class="ml-12" type="primary" @click="encode()"
          >转换👉</el-button
        >
        <el-input
          class="ml-12"
          style="width: 250px"
          v-model="result"
          :rows="8"
          placeholder="输出时间😀"
          clearable
        ></el-input>
        <el-popover
          placement="top"
          :auto-close="1000"
          trigger="click"
          popper-style="text-align: center;min-width:100px;width: 100px"
          content="复制成功！"
        >
          <template #reference>
            <el-button type="primary" class="ml-12" @click="copy()"
              >复制</el-button
            >
          </template>
        </el-popover>
      </el-form-item>
      <el-form-item label="Datetime">
        <el-date-picker
          style="width: 214px"
          v-model="inputValue1"
          type="datetime"
          placeholder="选择时间🤔"
          :default-time="defaultTime"
        />
        <el-button class="ml-12" type="primary" @click="decode()"
          >转换👉</el-button
        >
        <el-input
          class="ml-12"
          style="width: 250px"
          v-model="result1"
          :rows="8"
          placeholder="输出时间戳😀"
          clearable
        ></el-input>
        <el-popover
          placement="top"
          :auto-close="1000"
          trigger="click"
          popper-style="text-align: center;min-width:100px;width: 100px"
          content="复制成功！"
        >
          <template #reference>
            <el-button type="primary" class="ml-12" @click="copy1()"
              >复制</el-button
            >
          </template>
        </el-popover>
      </el-form-item>
    </el-form>
    <div class="introduce">
      <h2>什么是Unix时间戳？🤔</h2>
      <p>
        Unix时间戳，也称为Unix时间或POSIX时间，是一种将时间表示为自1970年1月1日协调世界时（UTC）00:00:00以来经过的秒数的系统（不包括闰秒）。它广泛用于计算机系统和编程语言中，用于给事件标记时间、测量时间间隔和执行涉及日期和时间的计算。
      </p>
      <p>
        Unix时间戳基于协调世界时（UTC）的概念，它是一种国际认可的时间标准。UTC用于确保在不同时区之间进行一致的时间记录，并不受夏令时变化的影响。
      </p>
      <p>
        Unix时间戳是一个表示自指定起始点以来经过的秒数的单个整数值。它通常以32位或64位有符号整数的形式存储，具体取决于编程语言或系统。该值每过一秒递增一次。
      </p>
      <p>一些Unix时间戳的示例：</p>
      <ul>
        <li>1970年1月1日00:00:00 UTC的Unix时间戳为0。</li>
        <li>
          2000年1月1日12:00:00
          UTC的Unix时间戳为946,684,800（表示两个日期之间的秒数）。
        </li>
        <li>
          可以通过查询系统或使用编程语言函数获取当前日期和时间的Unix时间戳。
        </li>
      </ul>
      <p>
        Unix时间戳在以标准化格式存储和比较日期和时间方面特别有用，这种格式独立于时区。它们通常用于数据库、日志文件和各种需要精确计时的应用程序中。
      </p>
      <p>
        在编程语言中使用Unix时间戳时，通常有内置函数或可用的库，允许在Unix时间戳和可读日期之间进行转换，以及执行添加或减去时间间隔等计算。
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-area {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
}
.ml-12 {
  margin-left: 12px;
}
.upload {
  margin-left: 12px;
}
</style>
