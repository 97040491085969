<script setup>
import { setMeta } from "@/utils/util";
import { ref, onMounted } from "vue";
import LuckyExcel from "luckyexcel";
import { exportExcel } from "@/utils/export";
onMounted(() => {
  document.title = "Excel Online Editor";
  setMeta("description", "Powerful Online Excel Editor.");
  setMeta("keywords", "Online Excel editing, Excel editing, Excel exporting, Excel online, Excel editor.");
  window.luckysheet.create({
    container: "luckysheet",
    title: 'New File',
    lang: "en",
  });
});

const jsonData = ref({});
let file = "";
const handleFileUpload = (uploadFile) => {
  file = uploadFile.raw;
  loadExcel();
};
const loadExcel = () => {
  const files = [file];
  if (files == null || files.length == 0) {
    alert("No files wait for import");
    return;
  }

  let name = files[0].name;
  let suffixArr = name.split("."),
    suffix = suffixArr[suffixArr.length - 1];
  if (suffix != "xlsx") {
    alert("Currently only supports the import of xlsx files");
    return;
  }
  LuckyExcel.transformExcelToLucky(files[0], function (exportJson) {
    if (exportJson.sheets == null || exportJson.sheets.length == 0) {
      alert(
        "Failed to read the content of the excel file, currently does not support xls files!"
      );
      return;
    }
    console.log("exportJson", exportJson);
    jsonData.value = exportJson;

    window.luckysheet.destroy();

    window.luckysheet.create({
      container: "luckysheet", //luckysheet is the container id
      showinfobar: true,
      data: exportJson.sheets,
      title: exportJson.info.name,
      userInfo: exportJson.info.name.creator,
      lang: "en",
    });
  });
};
const downloadExcel = () => {
  exportExcel(window.luckysheet.getAllSheets(), document.getElementById('luckysheet_info_detail_input').value);
};

const tipShow = ref(false)
const showTips = () => {
    tipShow.value = true
}
</script>

<template>
  <div>
    <div class="btn-area-excel">
      <!-- <input id="uploadBtn" type="file" @change="loadExcel" /> -->
      <h1>Excel Online Editor</h1>
      <el-tag class="ml-2" style="margin-left: 8px" type="warning">BETA</el-tag>
      <el-upload
        class="drop-upload"
        action=""
        :auto-upload="false"
        accept=".xlsx"
        :show-file-list="false"
        :on-change="handleFileUpload"
      >
        <template #trigger>
          <el-button style="margin-left: 15px" type="primary">Upload an XLSX file for editing.</el-button>
        </template>
      </el-upload>
      <el-button type="primary" @click="downloadExcel" style="margin-left: 15px">Download XLSX file.</el-button>
      <!-- <el-button type="primary" @click="showTips" style="margin-left: 15px">查看介绍🤔</el-button> -->
      
    </div>

    <div id="luckysheet"></div>
  </div>
</template>
<style lang="scss" scoped>
#luckysheet {
  margin: 0px;
  padding: 0px;
  width: calc(100vw - 40px);
  position: absolute;
  top: 130px;
  left: 20px;
  bottom: 70px;
}

#uploadBtn {
  font-size: 16px;
}
.btn-area-excel {
  position: absolute;
  left: 20px;
  top: 70px;
  display: flex;
  align-items: center;
}
</style>
<style>
.luckysheet-share-logo {
  background-image: none;
}
.luckysheet_info_detail div.luckysheet_info_detail_back {
  display: none;
}
</style>
